<listo-stepper [currentStep]="1"></listo-stepper>
<listo-tabs *ngIf="form.length > 1" [tabs]="getTabs()" [selectedIndex]="currentItem"
            (change)="onTabChange($event)"></listo-tabs>
<div class="container">
  <div class="image">
    <img src="../../assets/images/conductor.svg">
    <span *ngIf="form.controls.length > 1" class="badge">{{currentItem + 1}}</span>
  </div>
  <h2 class="header" [ngSwitch]="form.length">
    <ng-container *ngSwitchCase="1" i18n>Almost there{{name ? ' ' + name : ''}}! Now some information about you</ng-container>
    <ng-container *ngSwitchCase="5" i18n>You can't add more drivers</ng-container>
    <ng-container *ngSwitchDefault i18n>You can add up to {{5 - form.length}} more drivers</ng-container>
  </h2>
  <listo-button *ngIf="form.length > 1" [flat]="true" (click)="deleteDriver()" class="delete-button">
    <ng-container i18n>Delete</ng-container>
  </listo-button>
  <ng-container *ngFor="let vehicle of form.controls; let i = index">
    <app-driver-form [hidden]="i !== currentItem" [form]="vehicle" [showZipCode]="i === 0"></app-driver-form>
  </ng-container>
  <listo-button [disabled]="form.length >= 5" [invertColor]="true" (click)="addDriver()">
    <ng-container i18n>Add another driver</ng-container>
  </listo-button>
  <listo-button (click)="onNextClick()">
    <ng-container i18n>Next</ng-container>
    <span class="icon-arrow-right"></span>
  </listo-button>
  <listo-button [flat]="true" (click)="onBackClick()">
    <span class="icon-arrow-left"></span>
    <ng-container i18n>Back</ng-container>
  </listo-button>
</div>
