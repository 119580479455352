<listo-modal [open]="open" (close)="close.emit()">
  <div *ngIf="lang === 'en'" class="scroll-container">
    <h1>Privacy policy</h1>
    <p><em>Effective Date: April 4, 2017</em></p>
    <p>This Privacy Policy is intended to explain the types of information Listo Unlimited (“Company”) and its
      affiliates (“Company Group”) collects on Company Group websites, interactive services, email programs and mobile
      device applications (collectively, the “Website”). Please read this Privacy Policy carefully to get a clear
      understanding of how we collect, use, share, protect or otherwise handle your personal information in connection
      with your use of our Website. This Privacy Policy also explains how you can opt out of some of our uses and
      disclosures of your information.</p>
    <p>By using our Website, you consent to this Privacy Policy as it may be amended from time to time, and you agree
      that it governs your use of our Website and any dispute concerning the Website. Please take a few minutes to read
      and accept this Privacy Policy before registering for or accessing our Website.</p>
    <p>If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing
      practices, or if you want to report any security incidents to us, please contact us at the following address or
      phone number:<br><span data-preserve-html-node="true" style="text-decoration: underline">
Listo Unlimited Inc.<br>1177 S. King Road<br>San Jose, CA 95122<br>(408) 493-6497<br>privacy@listofin.com.
</span></p>
    <p>&nbsp;</p>
    <p><strong>Your Consent to Communications:</strong></p>
    <p>By providing your contact information to us, you consent to receive email messages, text messages, phone calls,
      faxes and postal mail, including that of a promotional nature, from the Company Group at the contact information
      you provide. Messages may be generated by an automated mechanism. You are not required to agree to this in order
      to purchase goods or services from us. You represent that you are legally competent and have legal authority to
      form a contract and provide this consent with respect to the contact information you provide. You may not consent
      on behalf of someone else or provide someone else’s contact information. We will obtain the date, time and content
      of your messages to us.</p>
    <p>You may elect to no longer receive promotional communications, and any communications to your mobile device, by
      contacting us at <span data-preserve-html-node="true"
                             style="text-decoration: underline">privacy@listofin.com</span> or using the unsubscribe
      link at the bottom of each email message.</p>
    <p>If we believe that the security of your personal information in our care may have been compromised, we may seek
      to notify you of that development. If a notification is appropriate, we will endeavor to notify you as promptly as
      possible under the circumstances. If we have your email address, we will notify you by email. You consent to our
      use of email as a means of such notification. If you prefer for use to use the U.S. Postal Service to notify you
      in this situation, please email us at <span data-preserve-html-node="true" style="text-decoration: underline">privacy@listofin.com</span>
      .</p>
    <p>&nbsp;</p>
    <p><strong>Reasons We Collect Your Information:</strong></p>
    <p>We collect your personal information because it helps us deliver a superior level of customer service, is
      necessary to fulfill requests for information or to provide requested services, and assists in the management of
      our business. We use your personal information to provide our services and to improve those services and our
      Website, to tell you about offers we think you will value, to customize the content you see and to conduct market
      research, which we may describe when we collect the information and, in certain cases, request your consent.</p>
    <p>&nbsp;</p>
    <p><strong>Information Collected Through Use of Our Website:</strong></p>
    <p>The information we gather through our Website falls into two categories: (1) information you voluntarily supply
      to us when you request information about an offering, to initiate transactions, such as making a purchase, request
      support, participate in promotions or surveys, submit comments or questions or otherwise communicate with us or
      our contractors, (2) information collected automatically as you use our Website.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Registration and Other Personally Identifying Information.
</span></p>
    <p>Personal information is information that can be used to identify you as an individual or allow someone to contact
      you. We may collect personal information about you when you register to use certain features of our Website,
      subscribe to a newsletter, fill out a form or otherwise enter information on our Website. When you do so, we may
      collect personal information, including your name, postal address, email address, phone number, location, user
      name and password, reminder questions and answers and communication preferences, such as which newsletters you
      would like to receive. In addition, when you request certain offerings we make available, we may need to check
      your credit history, which means we may request your social security number and date of birth. Finally, if you
      purchase certain products or services from us, we may collect information regarding your credit card or other
      payment information, employment and income information and/or your financial account status and balance.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Information Collected Automatically When You Use the Website.
</span></p>
    <p>The Company Group or third-party contractors we engage in support of our business may collect certain information
      automatically when you use our Website, including but not limited to:</p>
    <ul>
      <li>Web pages, content and advertisements you view and links you click while navigating within our Website;</li>
      <li>The sites you visited before and after visiting our Website;</li>
      <li>Your Internet Protocol (IP) address (a numerical address assigned to your computer by your Internet service
        provider so that other computers connected to the Internet can communicate with you online), which can sometimes
        be used to derive your general geographic location;
      </li>
      <li>Search terms you enter using our Website or on a referral site;</li>
      <li>Unique identifiers, including mobile device identification numbers, that can identify the physical location of
        devices in accordance with applicable law;
      </li>
      <li>Your device type, screen size, browser type, language, plug-ins, Internet domain, TCP configuration
        parameters, operating system and versions of installed software;
      </li>
      <li>Standard server log information; and</li>
      <li>Information collected through cookies, web beacons and other tracking technologies (see additional
        descriptions of these terms below).
      </li>
    </ul>
    <p>When you order via our Website, we may use a third-party payment processor to collect all information necessary
      to complete the transaction, including your name, credit card information and billing information. We do not store
      this information on our servers, but this information is processed through our third-party payment provider.</p>
    <p>When you send and receive MMS, SMS or other messages with the Company, we will collect the content of those
      messages, including any photos, files, text and other content you send or transmit or receive in order to provide
      the Services. We retain information on your behalf, such as files and messages that you store on our Website. If
      you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any
      other content included in the e-mail, in order to send you a reply.</p>
    <p>When you participate in one of our surveys, we may collect additional profile information. We also collect other
      types of personal information that you provide to us voluntarily, such as the information you provide when you
      contact us regarding support for the Website.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Personalization and Tracking Technologies.
</span></p>
    <p>Like most website and mobile application operators, we, or third parties acting on our behalf, use embedded
      scripts, "cookies", web beacons and other tracking technologies to operate, analyze and improve our Website and
      tailor content to user interests. Third-party companies that provide some of the tools and features accessible
      through our Website also may use tracking technologies to collect information about your use of our Website.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Cookies.
</span></p>
    <p>Cookies are small files stored on your computer or device that a site or its service provider transfers to your
      computer's hard drive through your Web browser (if you allow). Cookies enable the site's or service provider's
      systems to recognize your browser and capture and remember certain information. For instance, we use cookies to
      help us remember and process the items in your shopping cart. They are also used to help us understand your
      preferences based on previous or current site activity in order to provide you with improved services. We also use
      cookies to compile aggregate data about site traffic and site interaction so that we can offer better site
      experiences and tools in the future. We may also use trusted third party services that track this information on
      our behalf.</p>
    <p>You can set your browser to accept or reject most cookies, or to notify you when a cookie is set. (Each browser
      is different, so check the "Help" menu of your browser to learn how to change your cookie preferences.) It is up
      to you to decide whether to allow us or third parties to set cookies, but if you block cookies, you may not be
      able to view or access some of the features of our Website. However, you can still create an account. Please be
      aware that certain browsers cannot block or delete so-called "Flash" cookies, which use a feature of the Adobe
      Flash video player to store information on your computer. For information about how to delete Flash cookies,
      please visit the Adobe website.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Web Beacons and Other Tracking Technologies.
</span></p>
    <p>Web beacons (sometimes called "transparent GIFs," "clear GIFs," or "pixel tags") are small strings of code that
      embed a small graphic image (usually invisible) on a web page or in an email or advertisement. When your browser
      downloads a web beacon, the server that sends the corresponding image to your browser can recognize and store
      certain types of information such as cookies previously set, the time and date that you viewed the page embedded
      with the beacon and a description of that page.</p>
    <p>&nbsp;</p>
    <p><strong>Information You Provide to Facebook and other Social Networking Sites.</strong></p>
    <p>The Website may allow users to connect Facebook or other social network service (SNS) accounts to the Website.
      When you click on “Connect with Facebook,” or a similar connection on another SNS, you will be prompted to enter
      your SNS credentials or to “Sign Up” for the SNS. By proceeding, you are allowing the Website to access your
      information and you are agreeing to the Facebook or other SNS’s Terms of Use in your use of the Website. We may
      receive information from Facebook or another SNS to make it easier for you to create an account on the Website.
      Any information that we collect from your Facebook or other SNS account may depend on the privacy settings you
      have with that SNS, so please consult the SNS’s privacy and data practices.</p>
    <p>&nbsp;</p>
    <p><strong>Security of Credit Card and Bank Account Information.</strong></p>
    <p>For online payments and/or Automated Clearing House (ACH) payouts, we may use the payment services of Braintree,
      a service of Paypal, Inc. (<a href="https://www.braintreepayments.com">https://www.braintreepayments.com</a>)
      and/or Stripe, Inc. (<a href="https://www.stripe.com">https://www.stripe.com</a>). We do not process, record or
      maintain your credit card or bank account information. For more information on how payments are handled, or to
      understand the data security and privacy afforded such information, please refer to Braintree’s terms at <a
        href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">https://www.paypal.com/us/webapps/mpp/ua/privacy-full</a>
      and Stripe’s terms at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>.</p>
    <p>&nbsp;</p>
    <p><strong>Information Collected from You About Others:</strong></p>
    <p><strong>Email Invites.</strong></p>
    <p>If you decide to invite a third party to create an account, we will collect your and the third party’s names and
      e-mail addresses and/or phone number in order to follow up with the third party. We rely upon you to obtain
      whatever consents from the third party that may be required by law to allow us to access and upload the third
      party’s names and e-mail addresses as required above. You or the third party may contact us at
      privacy@listofin.com to request the removal of this information from our database.</p>
    <p>&nbsp;</p>
    <p><strong>Information We May Obtain From Other Sources:</strong></p>
    <p>We may combine any information collected through our Website with other information that we obtain about you,
      your computer, and/or device from other companies and sources, including third-party data suppliers and public
      databases. The following are examples of information we may collect from other sources:</p>
    <ul>
      <li>Name, postal address, email address and phone number;</li>
      <li>Demographic data, such as age and/or gender;</li>
      <li>If you request a loan, credit history;</li>
      <li>Publicly-visible data, such as your posts and submissions to blogs, video-sharing services, and other
        interactive forums; and
      </li>
      <li>Other navigation data, such as web sites.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>How We Use The Information We Collect:</strong></p>
    <p>Our primary goals in collecting information are to provide you with our products and services, answer your
      questions, provide support and customize your experience when using our Website to make it more valuable to you.
      We only use payment and identity verification information as necessary to complete a particular transaction,
      including payment, provide a requested service, enforce legal rights or comply with applicable law.</p>
    <p>In addition, we and our authorized service providers generally use other information that we or they collect
      about you to:</p>
    <ul>
      <li>Customize the content you see when you use our Website and other services as you navigate online;</li>
      <li>Analyze, operate and improve our Website, and fulfill your requests for products, services, and information,
        including to send you email and electronic newsletters;
      </li>
      <li>Send periodic emails regarding your order or other products and services;</li>
      <li>Invite you to Company Group events;</li>
      <li>Collect payments owed to us;</li>
      <li>Help recognize your browser as a previous visitor and save and remember any preferences that may have been set
        while your browser was visiting our Website;
      </li>
      <li>Enable you to participate in features such as surveys, polls, sweepstakes, and message boards</li>
      <li>Recognize the same users as they access online or mobile services using multiple browsers or devices and
        deliver to them appropriate interest-based content and advertising;
      </li>
      <li>Provide you with special offers, promotions and information from companies within the Company Group in
        accordance with applicable law;
      </li>
      <li>Help us measure and analyze visitor traffic and usage patterns and improve the quality and effectiveness of
        our content, features, advertisements, and other communications;
      </li>
      <li>Conduct market or audience analysis and related reporting;</li>
      <li>Understand whether our users read email or text messages and click on links contained within those messages;
      </li>
      <li>Prevent potentially prohibited or illegal activities, comply with applicable law, court orders and law
        enforcement, and enforce our and our users’ legal rights; and
      </li>
      <li>For any other purposes disclosed to you at the time we collect your information or pursuant to your consent.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>How We Disclose Information:</strong></p>
    <p>Except as otherwise expressly noted herein or on our Website, we may share any personal information collected
      through our Website with our service providers and with carefully selected third parties who may market related
      products and services that we believe may interest you. We may share your personal information:</p>
    <ul>
      <li>With our affiliates;</li>
      <li>With third parties who assist us in operating our Website, conducting our business, or servicing you, so long
        as those parties agree to keep this information confidential;
      </li>
      <li>Within the family of Company Group businesses;</li>
      <li>With credit bureaus, such as with respect to late payments, missed payments or other defaults on your
        account;
      </li>
      <li>To protect or defend the rights of Company or our users;</li>
      <li>Together with other assets, if all or any part of the Company Group is sold to another company; and</li>
      <li>When required by law or as we deem necessary or appropriate to respond to requests from public authorities.
      </li>
    </ul>
    <p>We will not publicly post or publicly display in any manner your social security number (a) unless authorized or
      required to do so by specific or federal state law or regulation or (b) for internal verification or
      administrative purposes. We may disclose your social security number to our third party service providers who
      obtain your credit history for us, help us collect unpaid amounts due, and extend credit to you.</p>
    <p>Your non-personally identifiable information may be provided to other parties for marketing, advertising, or
      other uses without notice.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Legal Compliance, Business Transfers and Other Disclosures.
</span></p>
    <p>Notwithstanding anything to the contrary stated herein or on our Website, we may occasionally release information
      about users of our Website when we deem such release appropriate to comply with law, respond to compulsory process
      or law enforcement requests, or protect the rights, property or safety of our customers or prospective customers,
      the public, the Company Group or any third party. Over time, we may reorganize or transfer various assets and
      lines of business. Notwithstanding anything to the contrary stated herein or on our Website, we reserve the right
      to disclose or transfer any information we collect to third parties in connection with any proposed or actual
      purchase, sale, lease, merger, foreclosure, liquidation, amalgamation or any other type of acquisition, disposal,
      transfer, conveyance or financing of all or any portion of the Company Group.</p>
    <p>&nbsp;</p>
    <p><strong>Call Recording:</strong></p>
    <p>When you speak with our customer service call center, your calls may be recorded for quality assurance, training,
      and verification purposes. By speaking with our call center, you consent to this.</p>
    <p>&nbsp;</p>
    <p><strong>Your Opt-Out Choices:</strong></p>
    <p>You have the right to limit some, but not all, sharing of certain personal information by Company. You have the
      right to prevent the specific Company Group entity from whom you obtain directly our products and services and/or
      to whom you have provided directly personal information from sharing your personal information, directly or
      through any affiliate, with nonaffiliate third parties:</p>
    <ul>
      <li>For its affiliates’ everyday business purposes, such as collecting information about your transactions,
        experience and creditworthiness;
      </li>
      <li>To enable its affiliates to market to you; and</li>
      <li>To enable nonaffiliates to market to you.</li>
    </ul>
    <p>Our “affiliates” include all entities within the Company Group other than the specific Company Group entity from
      whom you obtain directly our products and services and/or to whom you have provided directly personal information.
      “Nonaffiliates” are companies not within the Company Group; they may include both financial and nonfinancial
      companies.</p>
    <p>To exercise your rights under this Section and limit our ability to share your personal information:</p>
    <ul>
      <li>Call (408) 493-6497; or</li>
      <li>Email us at <span data-preserve-html-node="true" style="text-decoration: underline"> privacy@listofin.com
</span>.
      </li>
    </ul>
    <p>When you are no longer our customer, we may continue to share your information as described in this notice.
      However, you can contact us at any time to limit our sharing.</p>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Do Not Track.
</span></p>
    <p>We do not respond to “do not track” signals or similar mechanisms – where a visitor to the Website requests that
      we disable the collection of information about the visitor’s online activities, including navigation around our
      Website. We may also permit third parties to collect aggregate usage information on our website, and they may also
      not respond to “do not track” signals.</p>
    <p>&nbsp;</p>
    <p><strong>How to Correct or Update Your Information:</strong></p>
    <p>Please send us an email at <span data-preserve-html-node="true" style="text-decoration: underline">
privacy@listofin.com</span> .</p>
    <p>&nbsp;</p>
    <p><strong>How We Protect Information:</strong></p>
    <p>The Company Group uses commercially reasonable procedures to protect the personal information that we collect
      from you against loss, theft and misuse, as well as unauthorized access, disclosure, alteration and destruction.
      We have developed and implemented and continue to maintain and monitor a written information security program
      applicable to all records containing personal information. This security program is appropriate to the size, scope
      and type of our business, the resources available to us, the amount of stored data and the need for security and
      confidentiality of the personal information we store. Our Website is scanned on a regular basis for security holes
      and known vulnerabilities in order to make your visit to our site as safe as possible. We use regular Malware
      Scanning.</p>
    <p>No security system is impenetrable. We cannot guarantee the security of our databases or the security during
      transmission to us of the information you supply over the Internet. You can help us by taking precautions to
      protect your personal information when you are on the Internet. Change your passwords often using a combination of
      letters and numbers and make sure to use a secure web browser.</p>
    <p>For the purposes set out in this Privacy Policy, personal information may be transferred to, processed, stored
      and accessed by us, our affiliates and our unaffiliated service providers in the United States and in other
      jurisdictions where we or they operate. Courts and other authorities in these jurisdictions may, in certain
      circumstances, be entitled to access your personal information. By using the Website, you consent to this
      transfer, processing, storage and access of your personal information in and/or outside of the jurisdiction in
      which you reside.</p>
    <p>&nbsp;</p>
    <p><strong>Children Under 13:</strong></p>
    <p>The Company Group cares about protecting the privacy of children. We will not specifically market to or knowingly
      collect personal information from children under 13 for marketing purposes. Because some information is collected
      online, it may not appear to be the personal information of a child under 13. If a child under 13 submits personal
      information to us and we learn that the personal information is the information of a child under 13, we will
      attempt to delete the information as soon as possible. If you are under 13, please do not register for any of our
      services or provide us any information about yourself (such as your name, email address or phone number).</p>
    <p>&nbsp;</p>
    <p><strong>California Online Privacy Protection Act</strong></p>
    <p>As required by the California Online Privacy Protection Act (“California Act”) and the California Business and
      Professions Code, this Privacy Policy identifies the categories of personally identifiable information that we
      collect through our website about individual consumers who use or visit our website and the categories of
      third-party persons or entities with whom such personally identifiable information may be shared.</p>
    <p>Depending on the visitor’s activity at our website, certain “personally identifiable information” (as that term
      is defined in the California Act) may be collected, in addition to information set forth in other sections of this
      document. For purposes of the California Act, the term “personally identifiable information” means individually
      identifiable information about an individual consumer collected online by us from an individual and maintained by
      us in an accessible form, and may include any of the following: (1) a first and last name; (2) a home or other
      physical address, including street name and name of a city or town; (3) an email address; (4) a phone number; (5)
      a social security number; (6) any other identifier that permits the physical or online contacting of a specific
      individual; and (7) information concerning a user that the web site collects online from the use, and maintains in
      personally identifiable form, in combination with an identifier described within this Privacy Policy.</p>
    <p>See “Your Opt-Out Choices” and “How to Correct or Update Your Information” above for a description of the process
      maintained by the Company for an individual consumer who uses our Website to review and request changes to any of
      his or her personally identifiable information that is collected through our website.</p>
    <p>See “Changes to Our Online Privacy Policy” below for a description of the process by which we notify consumers
      who use our Website of material changes to our privacy policy.</p>
    <p>See more about the California Act at: <a
      href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a>.
    </p>
    <p>&nbsp;</p>
    <p><strong>Your California Privacy Rights:</strong></p>
    <p>Pursuant to California Civil Code Section 1798.83, if you are a California resident, you have the right to
      request and receive, once a year and free of charge, information about third parties to whom we have disclosed
      certain types of personal information (if any) about you for their direct marketing purposes in the prior calendar
      year, and a description of the categories of personal information shared. To make such a request, please send an
      email to <span data-preserve-html-node="true" style="text-decoration: underline">
privacy@listofin.com</span> and please include the phrase “California Privacy Request” in the subject line, the domain
      name of the website you are inquiring about, along with your name, address and email address. At our option, we
      may respond to such requests by providing instructions about how our users can exercise their options to prevent
      our disclosure of personal information to third parties for their direct marketing purposes. These instructions
      can be located above in the sections of our Online Privacy Policy titled "How We Disclose Information” and “Your
      Related Opt-out Choices.”</p>
    <p>&nbsp;</p>
    <p><strong>Your CAN SPAM Act Rights:</strong></p>
    <p>The CAN-SPAM Act sets the rules for commercial email, establishes requirements for commercial messages, gives
      recipients the right to prevent receipt of emails, and spells out penalties for violations.</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Reasons We Collect Your Email Address.
</span></p>
    <p>We collect your email address in order to:</p>
    <ul>
      <li>Send information and respond to inquiries and other requests or questions.</li>
      <li>Create your account and send information and updates pertaining to your service.</li>
      <li>Send you additional information related to your service and our Website.</li>
      <li>Market to our mailing list and continue to send emails to our customers after an initial transaction.</li>
    </ul>
    <p>We agree to the following with respect to our use of your email address:</p>
    <ul>
      <li>We will not use false, or misleading subjects or email addresses.</li>
      <li>We will identify the message as an advertisement in some reasonable way.</li>
      <li>We will include the phone number and physical address of our business or site headquarters.</li>
      <li>We will monitor third party email marketing services for compliance, if one is used.</li>
      <li>We will honor opt-out/unsubscribe requests quickly.</li>
      <li>We will allow users to unsubscribe by using the link at the bottom of each email.</li>
    </ul>
    <p>&nbsp;</p>
    <p><span data-preserve-html-node="true" style="text-decoration: underline">
Your Rights Under CAN-SPAM.
</span></p>
    <p>You can unsubscribe from receiving future emails by following the instructions at the bottom of each email you
      receive from us. We will promptly remove you from all correspondence.</p>
    <p>&nbsp;</p>
    <p><strong>Changes to Our Online Privacy Policy:</strong></p>
    <p>The Company Group reserves the right to revise, amend or modify this policy at any time and in any manner. When
      we change the policy in a material way a notice will be posted on our Website along with the updated privacy
      policy. Your use of our Website after such changes are implemented constitutes your acknowledgement and acceptance
      of those changes. Please consult this privacy statement prior to every use for any changes. Unless otherwise
      noted, all changes will be effective when posted.</p>
    <p>&nbsp;</p>
    <p><strong>Exclusions:</strong></p>
    <p><strong>This Privacy Policy does not govern the collection use or sharing of data by third parties or websites
      that may be linked to a Company Group website nor does it cover any information that may be collected by a third
      party in connection with a product or service provided by a third party even if you request information regarding
      such product or service on a Company Group website.</strong></p>
    <p>&nbsp;</p>
    <p><strong>Contacting Us:</strong></p>
    <p>If there are any questions regarding this Privacy Policy you may contact us using the information below.</p>
    <p>Email: <span data-preserve-html-node="true" style="text-decoration: underline">privacy@listofin.com</span></p>
    <p>Phone: (408) 493-6497</p>
  </div>
  <div *ngIf="lang === 'es'" class="scroll-container">
    <h1>Política de privacidad</h1>
    <p id="yui_3_17_2_1_1549053456972_129"><em>Efectiva desde el 4 de abril de 2017</em></p>
    <p>Esta Política de Privacidad tiene por objeto explicar los tipos de información que recoge Listo Unlimited
      ("Compañía") y sus afiliados ("Grupo") en los sitios web del Grupo, servicios interactivos, programas de correo
      electrónico y aplicaciones de dispositivos móviles (colectivamente, el "Sitio Web". Por favor, lea esta Política
      de Privacidad cuidadosamente para obtener una comprensión clara de cómo recopilamos, usamos, compartimos,
      protegemos o manejamos su información personal en conexión con su uso en nuestro Sitio Web. Esta Política de
      privacidad también explica cómo puede optar por no utilizar algunos de nuestros usos y divulgaciones de su
      información.</p>
    <p>Al utilizar nuestro Sitio Web usted da su consentimiento de uso y usted acepta dicha Politica de Privacidad que
      rige el uso de nuestro Sitio Web y cualquier disputa relacionada con el. Por favor, tome unos minutos para leer y
      aceptar esta Política de Privacidad antes de registrarse o acceder a nuestro Sitio Web. Esta Política de
      Privacidad, puede ser enmendada de vez en cuando.</p>
    <p>Si tiene alguna pregunta, inquietud o queja acerca de nuestra Política de privacidad o nuestras prácticas de
      recopilación de datos o de procesamiento, o si desea informarnos sobre cualquier incidente de seguridad,
      comuníquese con nosotros a la siguiente dirección o número de teléfono:<br><span data-preserve-html-node="true"
                                                                                       style="text-decoration:underline">
Listo Unlimited Inc.<br>1177 S. King Road<br>San Jose, CA 95122<br>(408) 493-6497<br>privacy@listofin.com
</span></p>
    <p><strong>Su consentimiento a las comunicaciones:</strong></p>
    <p>Al proporcionarnos su información de contacto, usted acepta recibir mensajes de correo electrónico, mensajes de
      texto, llamadas telefónicas, faxes y correo postal, incluyendo comunicaciones de carácter promocional del Grupo
      con la información de contacto que proporcione. Los mensajes pueden ser generados por un mecanismo automatizado.
      Usted no está obligado a aceptar esto para comprar bienes o servicios de nosotros. Usted declara que usted es
      legalmente competente y tiene autoridad legal para formar un contrato y proporcionar este consentimiento con
      respecto a la información de contacto que usted proporciona. Usted no puede consentir en nombre de otra persona o
      proporcionar la información de contacto de otra persona. La Compania mantendra registro de la fecha, hora y
      contenido de sus mensajes hacia nosotros.</p>
    <p>Puede optar por no recibir comunicaciones promocionales y cualquier comunicación con su dispositivo móvil,
      poniéndose en contacto con nosotros en <span data-preserve-html-node="true" style="text-decoration:underline">privacy@listofin.com</span>
      o utilizando el enlace de cancelación de suscripción en la parte inferior de cada mensaje de correo electrónico.
    </p>
    <p>Si en algún momento creemos que la seguridad de su información personal en nuestro poder pudo haber sido
      comprometida, trataremos de notificarle de la situación tan pronto como sea posible. Si una notificación es , nos
      esforzaremos por notificarle lo más rápidamente posible bajo las circunstancias necesarias. Si tenemos su
      dirección de correo electrónico, le notificaremos por correo electrónico. Usted acepta nuestro uso del correo
      electrónico como medio de dicha notificación. Si prefiere utilizar el Servicio Postal de los Estados Unidos para
      notificarle en esta situación, envíenos un correo electrónico a <span data-preserve-html-node="true"
                                                                            style="text-decoration:underline">privacy@listofin.com</span>.
    </p>
    <p><strong>Razones por las que recopilamos su información:</strong></p>
    <p>Recopilamos su información personal porque nos ayuda a brindar un nivel superior de servicio al cliente, nos
      ayuda a atender las solicitudes de información y/o para proporcionar servicios y asistir la gestión de nuestro
      negocio. Utilizamos su información personal para proporcionar nuestros servicios y para mejorar esos servicios y
      nuestro sitio web, para informarle sobre las ofertas que creemos que Usted valorará, para personalizar el
      contenido que vea y para llevar a cabo investigaciones de mercado, que podemos describir en ese momento y en
      ciertos casos, solicitar su consentimiento.</p>
    <p><strong>Información recolectada mediante el uso de nuestro sitio web:</strong></p>
    <p>La información que recopilamos a través de nuestro sitio web se divide en dos categorías: (1) información que
      voluntariamente nos suministra cuando solicita información sobre una oferta, para iniciar transacciones, como
      hacer una compra, solicitar apoyo, participar en promociones o encuestas, enviar comentarios O preguntas o
      comunicarse con nosotros o con nuestros contratistas, (2) información recolectada automáticamente a medida que use
      nuestro sitio web.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Registro y otra información de identificación personal.</span>
    </p>
    <p>La información personal es información que puede usarse para identificarle como persona o permitir que alguien se
      comunique con usted. Podemos recopilar información personal acerca de usted cuando se registra para usar ciertas
      cosas de nuestro sitio web, suscribirse a un boletín de noticias, llenar un formulario o de otra manera enviar
      información a nuestro sitio web. Cuando lo haga, podemos recopilar información personal, incluyendo su nombre,
      dirección postal, dirección de correo electrónico, número de teléfono, ubicación, nombre de usuario y contraseña,
      preguntas y respuestas de prueba y preferencias de comunicación, como los boletines que desea recibir. Además,
      cuando solicite ciertas ofertas que ponemos a disposición, es posible que debamos revisar su historial de crédito,
      lo que significa que es posible que solicitemos su número de seguro social y fecha de nacimiento. Finalmente, si
      compra ciertos productos o servicios de nosotros, podemos recopilar información sobre su tarjeta de crédito u otra
      información de pago, información sobre empleo e ingresos y / o su estado y saldo de su cuenta financiera.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Información recopilada automáticamente cuando utiliza el sitio web.</span>
    </p>
    <p>El Grupo o terceros contratistas que contratamos para apoyar nuestro negocio pueden recopilar cierta información
      automáticamente cuando usted usa nuestro Sitio Web, incluyendo pero no limitadose a:</p>
    <ul>
      <li>Las páginas web, el contenido y los anuncios que usted ve y enlaces que haga clic mientras navega dentro de
        nuestro sitio web;
      </li>
      <li>Los sitios que visitó antes y después de visitar nuestro sitio web;</li>
      <li>Su dirección de Protocolo de Internet (IP) (una dirección numérica asignada a su computadora por su proveedor
        de servicios de Internet para que otros equipos conectados a Internet puedan comunicarse con usted en línea),
        que a veces puede usarse para derivar su ubicación geográfica general;
      </li>
      <li>Términos de búsqueda que ingrese usando nuestro Sitio Web o en un sitio de referencia;</li>
      <li>Identificadores incluyendo números de identificación de dispositivos móviles, que puedan identificar la
        ubicación física de los dispositivos de acuerdo con la ley aplicable;
      </li>
      <li>El tipo de dispositivo, el tamaño de la pantalla, el tipo de navegador, el idioma, los complementos, el
        dominio de Internet, los parámetros de configuración TCP, el sistema operativo y las versiones del software
        instalado;
      </li>
      <li>Información de registro del servidor estándar; y</li>
      <li>Información recolectada a través de cookies, web beacons y otras tecnologías de seguimiento (ver descripciones
        adicionales de estos términos a continuación).
      </li>
    </ul>
    <p>Cuando haga una solicitud a través de nuestro sitio web, podemos usar un procesador de pagos tercerizado para
      recopilar toda la información necesaria para completar la transacción, incluyendo su nombre, información de la
      tarjeta de crédito e información de facturación. No almacenamos esta información en nuestros servidores, pero esta
      información se procesara a través de nuestro proveedor de pagos.</p>
    <p>Cuando envíe y reciba mensajes MMS, SMS u otros con la Compañía, recopilaremos el contenido de esos mensajes,
      incluidas las fotos, archivos, textos y otros contenidos que envíe o transmita o reciba para proporcionar los
      Servicios. Retenemos información en su nombre, como archivos y mensajes que almacena en nuestro sitio web. Si nos
      proporciona su opinión o nos contacta por correo electrónico, recogeremos su nombre y dirección de correo
      electrónico, así como cualquier otro contenido incluido en el correo electrónico, para enviarle una respuesta.</p>
    <p>Cuando participe en una de nuestras encuestas, es posible que recopilemos información adicional sobre el perfil.
      También recopilamos otros tipos de información personal que nos proporcione voluntariamente, como la información
      que nos proporciona cuando nos contacta con respecto al soporte para el Sitio Web.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Personalización y Seguimiento de Tecnologías.</span>
    </p>
    <p>Como la mayoría de los operadores de sitios web y aplicaciones móviles, nosotros, o terceros que actúan en
      nuestro nombre, utilizamos scripts integrados, "cookies", web beacons y otras tecnologías de seguimiento para
      operar, analizar y mejorar nuestro sitio web y adaptar el contenido a los intereses del usuario. Las compañías de
      terceros que proporcionan algunas de las herramientas y funciones accesibles a través de nuestro sitio web también
      pueden utilizar tecnologías de seguimiento para recopilar información sobre su uso de nuestro sitio web.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Cookies.</span></p>
    <p>Las cookies son pequeños archivos almacenados en su computadora o dispositivo que un sitio o su proveedor de
      servicios transfiere al disco duro de su computadora a través de su navegador Web (si lo permite). Las cookies
      permiten que el sistema del sitio o del proveedor de servicios reconozca su navegador y capture y recuerde cierta
      información. Por ejemplo, utilizamos cookies para ayudarnos a recordar y procesar los elementos de su carrito de
      compras. También se utilizan para ayudarnos a entender sus preferencias en función de la actividad anterior o
      actual del sitio con el fin de ofrecerle servicios mejorados. También usamos cookies para compilar datos agregados
      sobre el tráfico del sitio y la interacción del sitio para que podamos ofrecer mejores experiencias y herramientas
      en el futuro. También podemos utilizar servicios de terceros de confianza que rastrean esta información en nuestro
      nombre.</p>
    <p>Puede configurar su navegador para aceptar o rechazar la mayoría de las cookies o para notificarle cuando se
      establece una cookie. (Cada navegador es diferente, así que consulte el menú "Ayuda" de su navegador para saber
      cómo cambiar las preferencias de las cookies). Depende de usted decidir si debemos permitir que nosotros o
      terceros establezcamos cookies, pero si bloquea las cookies, Es posible que no pueda ver ni acceder a algunas de
      las funciones de nuestro sitio web. Sin embargo, todavía puede crear una cuenta. Tenga en cuenta que algunos
      navegadores no pueden bloquear o eliminar las denominadas cookies "Flash", que utilizan una característica del
      reproductor de vídeo de Adobe Flash para almacenar información en su computadora. Para obtener información acerca
      de cómo eliminar las cookies de Flash, visite el sitio web de Adobe.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Web Beacons y otras tecnologías de seguimiento.</span>
    </p>
    <p>Los beacons web (a veces llamados "GIF transparentes", "GIF claros" o "etiquetas de píxeles") son pequeñas
      secuencias de código que incrustan una pequeña imagen gráfica (normalmente invisible) en una página web o en un
      correo electrónico o anuncio. Cuando el navegador descarga un beacon web, el servidor que envía la imagen
      correspondiente a su navegador puede reconocer y almacenar ciertos tipos de información, como las cookies
      previamente configuradas, la hora y la fecha en que vio la página incrustada con el beacon y una descripción de la
      misma página.</p>
    <p><strong>Información que proporciona a Facebook y otros sitios de redes sociales.</strong></p>
    <p>El sitio web puede permitir que los usuarios conecten cuentas de Facebook o de otras redes sociales (SNS) al
      sitio web. Al hacer clic en "Conectarse con Facebook", o una conexión similar en otro SNS, se le pedirá que
      introduzca sus credenciales SNS o "Registrarse" para el SNS. Al continuar, usted está permitiendo que el Sitio Web
      tenga acceso a su información y está de acuerdo con las Condiciones de Uso de Facebook o de otro SNS en su uso del
      Sitio Web. Es posible que recibamos información de Facebook u otro SNS para facilitar la creación de una cuenta en
      el sitio web. Cualquier información que recopilamos de su cuenta de Facebook o de otro SNS puede depender de la
      configuración de privacidad que tenga con ese SNS, por lo que consulte las prácticas de privacidad y datos del
      SNS.</p>
    <p><strong>Seguridad de la tarjeta de crédito y la información de la cuenta bancaria.</strong></p>
    <p>Para pagos en línea y / o pagos automatizados de la Cámara de Compensación (ACH), podemos usar los servicios de
      pago de Braintree, un servicio de Paypal, Inc. (<a href="https://www.braintreepayments.com">https://www.braintreepayments.com</a>)
      y / o Stripe, Inc. (https : //www.stripe.com). No procesamos, registramos ni mantenemos su información de tarjeta
      de crédito o de cuenta bancaria. Para obtener más información sobre cómo se manejan los pagos, o para entender la
      seguridad de los datos y la privacidad que ofrece dicha información, consulte los términos de Braintree en <a
        href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">https://www.paypal.com/us/webapps/mpp/ua/privacy-full</a>
      y Stripe's Términos en <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>.</p>
    <p><strong>Información recopilada de usted sobre otros:</strong></p>
    <p><strong>Invitaciones por correo electrónico.</strong></p>
    <p>Si decide invitar a otras personas a crear una cuenta, recopilaremos sus nombre y los de terceros y direcciones
      de correo electrónico y / o número de teléfono con el fin de dar seguimiento a esas personas. Confiamos en usted
      para obtener cualquier consentimiento de tercero que pueda ser requerido por la ley para permitirnos acceder y
      cargar los nombres de dichos terceros y direcciones de correo electrónico como se requiere arriba. Usted o
      cualquier otra persona puede ponerse en contacto con nosotros en privacy@listofin.com para solicitar la
      eliminación de esta información de nuestra base de datos.</p>
    <p><strong>Información que podemos obtener de otras fuentes:</strong></p>
    <p>Podemos combinar cualquier información recopilada a través de nuestro Sitio Web con otra información que
      obtengamos de usted, su computadora y / o dispositivo de otras compañías y fuentes, incluyendo proveedores de
      datos de terceros y bases de datos públicas. Los siguientes son ejemplos de información que podemos recopilar de
      otras fuentes:</p>
    <ul>
      <li>Nombre, dirección postal, dirección de correo electrónico y número de teléfono;</li>
      <li>Datos demográficos, como edad y / o sexo</li>
      <li>Historial de crédito (en el caso que solicite un préstamo)</li>
      <li>Datos o información de publico conocimiento, como información incluida en perfiles publicos y blogs, servicios
        de video compartido y otros foros interactivos; y
      </li>
      <li>Datos de navegación a través de la web y otros sitios web.</li>
    </ul>
    <p><strong>Cómo utilizamos la información que recopilamos:</strong></p>
    <p>Nuestros principales objetivos en la recolección de información son proporcionarle nuestros productos y
      servicios, responder a sus preguntas, proporcionar apoyo y personalizar su experiencia al utilizar nuestro sitio
      web para que sea más valioso para usted. Utilizamos únicamente la información de verificación de pago y de
      identidad según sea necesario para completar una transacción en particular, incluyendo el pago, proporcionar un
      servicio solicitado, hacer cumplir los derechos legales o cumplir con la ley aplicable.</p>
    <p>Además, nosotros usamos y nuestros proveedores de servicios autorizados usan información adicional recopiladada
      sobre usted para:</p>
    <ul>
      <li>Personalizar el contenido que ve cuando utiliza nuestro sitio Web y otros servicios mientras navega en
        línea;
      </li>
      <li>Analizar, operar y mejorar nuestro sitio web, y satisfacer sus solicitudes de productos, servicios e
        información, incluyendo información para enviarle correos y boletines electrónicos;
      </li>
      <li>Enviar correos electrónicos periódicos con respecto a su pedido u otros productos y servicios;</li>
      <li>Invitarle a eventos del Grupo;</li>
      <li>Recoger los pagos;</li>
      <li>Ayudar a reconocer su navegador como visitante anterior y guardar y recordar las preferencias que se hayan
        establecido mientras su navegador visitaba nuestro sitio web;
      </li>
      <li>Invitarle y permitirle participar en actividades tales como encuestas, encuestas, sorteos y tableros de
        mensajes;
      </li>
      <li>Reconocer a los mismos usuarios que acceden a servicios en línea o móviles utilizando múltiples navegadores o
        dispositivos y entregarles contenido y publicidad apropiados basados en intereses;
      </li>
      <li>Proporcionarle ofertas especiales, promociones e información de compañías dentro del Grupo de acuerdo con la
        ley aplicable;
      </li>
      <li>Ayudar a medir y analizar el tráfico de visitantes y los patrones de uso y mejorar la calidad y efectividad de
        nuestro contenido, características, anuncios y otras comunicaciones;
      </li>
      <li>Realizar análisis de mercado o de audiencia e informes relacionados;</li>
      <li>Entender si nuestros usuarios leen correo electrónico o mensajes de texto y hacen clic en los enlaces
        contenidos en esos mensajes;
      </li>
      <li>Evitar actividades prohibidas o ilegales, cumplir con la ley aplicable, órdenes judiciales y la aplicación de
        la ley, y hacer valer los derechos legales de nuestros usuarios y de nuestros usuarios; y
      </li>
      <li>Para cualquier otro propósito el cual le será comunicado para obtener su consentimiento y poder capturar tal
        información adicional.
      </li>
    </ul>
    <p><strong>Cómo divulgamos la información:</strong></p>
    <p>Salvo que se indique expresamente lo contrario aquí o en nuestro sitio web, podemos compartir cualquier
      información personal recopilada a través de nuestro sitio web con nuestros proveedores de servicios y con terceros
      cuidadosamente seleccionados que pueden comercializar productos y servicios que creemos que pueden interesarle.
      Podemos compartir su información personal:</p>
    <ul>
      <li>Con nuestros afiliados;</li>
      <li>Con terceros que nos ayuden en operar nuestro sitio web, dirigir nuestro negocio o prestarle servicios,
        siempre y cuando dichas partes acuerden mantener esta información confidencial;
      </li>
      <li>Dentro de la familia de negocios del Grupo;</li>
      <li>Con agencias de crédito, como con respecto a pagos atrasados, pagos perdidos u otros incumplimientos en su
        cuenta;
      </li>
      <li>Proteger o defender los derechos de la Compañía o de nuestros usuarios;</li>
      <li>Junto con otros activos, si toda o cualquier parte del Grupo se vende a otra compañía; y</li>
      <li>Cuando lo requiera la ley o cuando lo consideremos necesario o apropiado para responder a las solicitudes de
        las autoridades públicas.
      </li>
    </ul>
    <p>No publicaremos ni compartiremos de manera alguna su número de seguro social (a) a menos que sea autorizado o
      requerido por ley o regulación específica o federal, o (b) para verificación interna o para fines administrativos.
      Es posible que revelemos su número de seguro social a nuestros proveedores de servicios con el fin de obtener su
      historial de crédito, cobrar cantidades pendientes de pago y otorgarle credito.</p>
    <p>Su información puede ser proporcionada en forma anonima otras empresas para fines de marketing, publicidad u
      otros usos sin previo aviso.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Cumplimiento Legal, Transferencias Comerciales y Otras Divulgaciones.</span>
    </p>
    <p>Sin perjuicio de lo contrario a lo aquí expresado o en nuestro Sitio Web, ocasionalmente podemos liberar
      información sobre los usuarios de nuestro Sitio Web cuando consideremos que tal liberación es apropiada para
      cumplir con la ley, responder a solicitudes obligatorias de procesamiento o aplicación de la ley o proteger los
      derechos, propiedad o seguridad De nuestros clientes o potenciales clientes, del público, del Grupo o de cualquier
      tercero. Con el tiempo, podemos reorganizar o transferir activos y líneas de negocio. Sin perjuicio de lo
      contrario a lo indicado en este documento o en nuestro sitio web, nos reservamos el derecho de revelar o
      transferir cualquier información que recopilamos a terceros en relación con cualquier propuesta o real de compra,
      venta, arrendamiento, fusión, ejecución hipotecaria, liquidación, Tipo de adquisición, disposición, transferencia,
      transmisión o financiamiento de la totalidad o parte del Grupo de la Sociedad.</p>
    <p><strong>Grabacion de llamada:</strong></p>
    <p>Cuando hable con nuestro centro de atención al cliente, sus llamadas podran ser registradas para fines de
      aseguramiento de calidad, capacitación y verificación. Al hablar con nuestro centro de llamadas, usted acepta
      esto.</p>
    <p><strong>Sus Opciones de Opt-Out:</strong></p>
    <p>Usted tiene el derecho de limitar que compartamos cierta, pero no toda, información personal recabada por la
      Compañía. Usted tiene el derecho de impedir que la entidad específica del Grupo de quien usted obtenga
      directamente nuestros productos y servicios y / o a quien usted haya proporcionado información personal
      directamente, comparta su información personal, directamente a través de cualquier afiliado, con terceros no
      afiliados:</p>
    <ul>
      <li>Para los fines cotidianos de sus afiliados, tales como recopilar información sobre sus transacciones,
        experiencia y solvencia;
      </li>
      <li>Permitir que sus afiliados le introduzcan en el mercado; y</li>
      <li>Permitir a los no afiliados comunicarse y comercializar con usted.</li>
    </ul>
    <p>Nuestros "afiliados" incluyen todas las entidades dentro del Grupo de la Compañía que no sean la entidad
      específica del Grupo de la cual usted obtiene directamente nuestros productos y servicios y/o a quien usted ha
      proporcionado información personal directamente. "No affiliados" son empresas que no pertenecen al Grupo y pueden
      incluir compañías financieras y no financieras.</p>
    <p>Para ejercer sus derechos bajo esta Sección y limitar nuestra capacidad de compartir su información personal:</p>
    <ul>
      <li>Llame al (408) 493-6497; o</li>
      <li>Envíenos un correo electrónico a <span data-preserve-html-node="true" style="text-decoration:underline">privacy@listofin.com</span>.
      </li>
    </ul>
    <p>Cuando ya no es nuestro cliente, podemos seguir compartiendo su información como se describe en este aviso. Sin
      embargo, puede ponerse en contacto con nosotros en cualquier momento para limitar nuestro intercambio.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">No seguimiento.</span></p>
    <p>No respondemos a señales de "no rastrear" o mecanismos similares - cuando un visitante del sitio web solicita que
      inhabilitamos la recopilación de información sobre las actividades en línea del visitante, incluida la navegación
      en nuestro sitio web. También podemos permitir que terceros recopilen información de uso agregado en nuestro sitio
      web, y es posible que ellos tampoco respondan a señales de "no rastrear".</p>
    <p><strong>Cómo corregir o actualizar su información:</strong></p>
    <p>Por favor envíenos un correo electrónico a privacy@listofin.com .</p>
    <p><strong>Cómo protegemos la información:</strong></p>
    <p>El Grupo de la Compañía utiliza procedimientos comercialmente razonables para proteger la información personal
      que recopilamos de usted contra la pérdida, robo y uso indebido, así como el acceso no autorizado, divulgación,
      alteración y destrucción. Hemos desarrollado e implementado y continuamos manteniendo y monitoreando un programa
      escrito de seguridad de información aplicable a todos los registros que contienen información personal. Este
      programa de seguridad es apropiado para el tamaño, alcance y tipo de nuestro negocio, los recursos disponibles
      para nosotros, la cantidad de datos almacenados y la necesidad de seguridad y confidencialidad de la información
      personal que almacenamos. Monitoreamos en forma regular para ver si hay fallas de seguridad y vulnerabilidades
      conocidas para que su visita a nuestro sitio sea lo más segura posible. Utilizamos herramientas abituales de
      monitoreo de software dañino (virus, etc).</p>
    <p>Ningún sistema de seguridad es impenetrable. No podemos garantizar la seguridad de nuestras bases de datos ni la
      seguridad durante la transmisión de la información que usted suministra a través de Internet. Usted puede
      ayudarnos tomando precauciones para proteger su información personal cuando está en Internet. Cambie sus
      contraseñas a menudo usando una combinación de letras y números y asegúrese de usar un navegador web seguro.</p>
    <p>Para los propósitos establecidos en esta Política de Privacidad, la información personal puede ser transferida,
      procesada, almacenada y accedida por nosotros, nuestros afiliados y nuestros proveedores de servicios no afiliados
      en los Estados Unidos y en otras jurisdicciones en las que operamos. Los tribunales y otras autoridades de estas
      jurisdicciones pueden, en ciertas circunstancias, tener derecho a acceder a su información personal. Al utilizar
      el sitio web, usted acepta esta transferencia, procesamiento, almacenamiento y acceso de su información personal
      dentro y / o fuera de la jurisdicción en la que reside.</p>
    <p><strong>Niños menores de 13 años:</strong></p>
    <p>El Grupo de la Empresa se preocupa por proteger la privacidad de los niños. No comercializaremos específicamente
      o recopilaremos información personal de niños menores de 13 años para propósitos de marketing. Debido a que cierta
      información se recopila en línea, puede que no sea evidente que pertenezca a un menor de 13 años. Si un menor de
      13 años nos envía información personal y nos enteramos de que la información personal es la información de un niño
      menor de 13 años, intentaremos eliminar la información tan pronto como sea posible. Si usted es menor de 13 años,
      por favor no se registre para ninguno de nuestros servicios o nos proporcione información sobre usted (como su
      nombre, dirección de correo electrónico o número de teléfono).</p>
    <p><strong>Ley de protección de la privacidad en línea de California</strong></p>
    <p>Esta Política de Privacidad identifica las categorías de información de identificación personal que recopilamos a
      través de nuestro sitio web acerca de los consumidores individuales que usan o visitan nuestro sitio web y el
      Código de Negocios y Profesiones de California. Categorías de terceros o entidades con las que dicha información
      de identificación personal pueda ser compartida.</p>
    <p>Dependiendo de la actividad del visitante en nuestro sitio web, se puede recopilar cierta "información de
      identificación personal" (tal como se define ese término en la Ley de California), además de la información
      expuesta en otras secciones de este documento. Para los propósitos de la Ley de California, el término
      "información de identificación personal" significa información individualmente identificable sobre un consumidor
      individual recopilada en línea y mantenida por nosotros en forma accesible, y puede incluir cualquiera de los
      siguientes: Nombre y apellido; (2) domicilio u otra dirección física, incluyendo el nombre de la calle y el nombre
      de una ciudad o pueblo; (3) dirección de correo electrónico; (4) número de teléfono; (5) número de seguro social;
      (6) cualquier otro identificador que permita el contacto físico o en línea de un individuo específico; y (7)
      información relativa a un usuario que el sitio web recoge en línea del uso y mantiene en forma individual, en
      combinación con un identificador descrito en esta Política de Privacidad.</p>
    <p>Consulte "Sus opciones de exclusión" y "Cómo corregir o actualizar su información" para una descripción del
      proceso mantenido por la Compañía para un consumidor individual que usa nuestro sitio web para revisar y solicitar
      cambios a cualquiera de sus identificaciones personales Información que se recopila a través de nuestro sitio
      web.</p>
    <p>Consulte "Cambios a nuestra política de privacidad en línea" a continuación para obtener una descripción del
      proceso mediante el cual notificamos a los consumidores que usan nuestro sitio web los cambios importantes en
      nuestra política de privacidad.</p>
    <p>Vea más sobre la Ley de California en: <a
      href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a>.
    </p>
    <p><strong>Sus Derechos de Privacidad de California:</strong></p>
    <p>De conformidad con la Sección 1798.83 del Código Civil de California, si usted es residente de California, tiene
      derecho a solicitar y recibir, una vez al año y sin cargo, información sobre terceros a quienes hemos revelado
      ciertos tipos de información personal (si la hubiere) Sobre usted para sus propósitos de marketing directo en el
      año calendario anterior, y una descripción de las categorías de información personal compartida. Para hacer tal
      solicitud, envíe un correo electrónico a <span data-preserve-html-node="true" style="text-decoration:underline">privacy@listofin.com</span>
      y por favor incluya la frase "Solicitud de privacidad de California" en la línea de asunto, el nombre de dominio
      del sitio web que está preguntando, junto con su nombre, dirección y correo electrónico dirección. A nuestra
      discreción, podemos responder a dichas solicitudes proporcionando instrucciones sobre cómo nuestros usuarios
      pueden ejercer sus opciones para impedir que divulguemos información personal a terceros para fines de marketing
      directo. Estas instrucciones pueden encontrarse en las secciones de nuestra Política de privacidad en línea
      titulada "Cómo divulgamos información" y "Sus opciones de exclusión relacionadas".</p>
    <p><strong>Sus derechos de la Ley CAN SPAM:</strong></p>
    <p>La Ley CAN-SPAM establece las reglas para el correo electrónico comercial, establece los requisitos para los
      mensajes comerciales, otorga a los destinatarios el derecho de impedir la recepción de correos electrónicos y
      especifica las sanciones por infracciones.</p>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Razones por las que recopilamos su dirección de correo electrónico.</span>
    </p>
    <p>Recopilamos su dirección de correo electrónico para:</p>
    <ul>
      <li>Enviar información y responder a preguntas y otras peticiones o preguntas.</li>
      <li>Crear su cuenta y envíar información relacionada con su servicio.</li>
      <li>Enviarle información adicional relacionada con su servicio y nuestro sitio web.</li>
      <li>Ofrecer productos y servicios y seguir enviando correos electrónicos a nuestros clientes después de una
        transacción inicial.
      </li>
    </ul>
    <p>Aceptamos lo siguiente con respecto a nuestro uso de su dirección de correo electrónico:</p>
    <ul>
      <li>No utilizaremos mensajes falsos, engañosos o direcciones de correo electrónico falsas.</li>
      <li>Identificaremos de manera razonable el mensaje como un anuncio.</li>
      <li>Incluiremos teléfono, dirección física de nuestro negocio y/o dirección de nuestro sitio web.</li>
      <li>Monitorizaremos los servicios de marketing por correo electrónico de terceros para el cumplimiento, si uno se
        utiliza.
      </li>
      <li>Honraremos las solicitudes de exclusión / cancelación de la solicitud rápidamente.</li>
      <li>Permitiremos a los usuarios cancelar la suscripción usando el enlace al final de cada correo electrónico.</li>
    </ul>
    <p><span data-preserve-html-node="true" style="text-decoration:underline">Sus derechos bajo CAN-SPAM.</span></p>
    <p>Puede darse de baja de recibir correos electrónicos futuros siguiendo las instrucciones al pie de cada correo
      electrónico que reciba de nosotros. Le retiraremos de inmediato de toda correspondencia.</p>
    <p><strong>Cambios a nuestra política de privacidad en línea:</strong></p>
    <p>El Grupo se reserva el derecho de revisar, enmendar o modificar esta política en cualquier momento y de cualquier
      manera. Cuando cambiemos la política de manera material, se publicará un aviso en nuestro sitio web junto con la
      política de privacidad actualizada. El uso de nuestro sitio web después de que se implementen dichos cambios
      constituye reconocimiento y aceptación de dichos cambios. Por favor, en el caso de cambios, consulte esta
      declaración de privacidad antes usar el sitio web. A menos que se indique lo contrario, todos los cambios serán
      efectivos cuando se publiquen.</p>
    <p><strong>Exclusiones:</strong></p>
    <p>&amp;&amp;Esta Política de Privacidad no regula el uso de la recopilación o el intercambio de datos por parte de
      terceros o sitios web que pueden estar vinculados a un sitio web del Grupo ni cubre información que pueda ser
      recopilada por terceros en relación con un producto o servicio de un tercero, incluso si solicita información
      sobre dicho producto o servicio en un sitio web del Grupo.**</p>
    <p><strong>Cómo ponerse en contacto con nosotros:</strong></p>
    <p>Si hay alguna pregunta con respecto a esta Política de Privacidad, puede ponerse en contacto con nosotros
      utilizando la información que aparece a continuación.</p>
    <p>Correo electrónico: <span data-preserve-html-node="true"
                                 style="text-decoration:underline">privacy@listofin.com</span><br>Teléfono: (408)
      493-6497</p>
  </div>
  <listo-button (click)="close.emit()" i18n>Back</listo-button>
</listo-modal>
