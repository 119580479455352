import { Component, EventEmitter, Input, Output } from '@angular/core';

const getLastDayInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

@Component({
  selector: 'listo-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
})
export class DateSelectComponent {
  @Input() label?: string;
  @Input() required = false;
  @Input() value: Date = new Date();
  @Output() change: EventEmitter<Date> = new EventEmitter();

  months = Array.from({ length: 12 }, (_, i) => ({
    value: i,
    label: i + 1,
  }));

  hours = Array.from({ length: 24 }, (_, i) => ({
    value: i,
    label: `${i}:00 hrs`,
  }));

  get days() {
    if (!this.value) {
      return [];
    }
    const length = getLastDayInMonth(this.year, this.month);
    return Array.from({ length }, (_, i) => i + 1);
  }

  get year() {
    return this.value && this.value.getFullYear();
  }

  get month() {
    return this.value && this.value.getMonth();
  }

  get day() {
    return this.value && this.value.getDate();
  }

  get hour() {
    return this.value && this.value.getHours();
  }

  onMonthChange(month) {
    const lastDayInMonth = getLastDayInMonth(this.year, month);
    this.onChange({
      month,
      day: Math.min(this.day, lastDayInMonth) || 1,
    });
  }

  onDayChange(day) {
    this.onChange({ day });
  }

  onHourChange(hour) {
    this.onChange({ hour });
  }

  onChange({
    month = this.month !== undefined ? this.month : new Date().getMonth(),
    day = this.day || new Date().getDate(),
    hour = this.hour || new Date().getHours(),
  }) {
    const date = new Date(
      new Date().getFullYear(),
      month,
      day,
      hour,
      0,
      0,
      0,
    );
    if (date < new Date()) {
      date.setFullYear(new Date().getFullYear() + 1);
    }
    this.change.emit(date);
  }
}
