import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-items-card',
  templateUrl: './items-card.component.html',
  styleUrls: ['./items-card.component.scss'],
})
export class ItemsCardComponent {
  @Input() header: string;
  open = false;
}
