import { Component } from '@angular/core';

@Component({
  selector: 'listo-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent {
  privacyModalOpen = false;
}
