import { Component, Input } from '@angular/core';
import { PHONE_NUMBER, PHONE_NUMBER_EVENT } from '../../../utils/constants';
import { getSegmentPage } from '../../../utils/operations';
import { SegmentService } from '../../segment.service';

@Component({
  selector: 'listo-phone-link',
  templateUrl: './phone-link.component.html',
  styleUrls: ['./phone-link.component.scss'],
})
export class PhoneLinkComponent {
  @Input() class: string;
  PHONE_NUMBER = PHONE_NUMBER;
  url: string;

  constructor(private segment: SegmentService) {}

  sendEvent() {
    const eventName = `${getSegmentPage()} ${PHONE_NUMBER_EVENT}`;
    this.segment.track(eventName);
  }
}
