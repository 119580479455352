<img i18n-src src="../../assets/images/welcome_en.svg">
<h2 i18n>Let us give you the best experience</h2>
<form [formGroup]="form" (submit)="onNextClick()">
  <listo-input [form]="form" name="name" i18n-label label="What's your name?" [required]="true"></listo-input>
  <listo-button>
    <ng-container i18n>Next</ng-container>
    <span class="icon-arrow-right"></span>
  </listo-button>
  <listo-button [flat]="true" i18n type="button" (click)="onNextClick()">
    Continue as anonymous
  </listo-button>
</form>
