<label [formGroup]="form" [class.error]="!!error">
  <span *ngIf="label && (focus || error)" class="icon-focus"></span>
  {{label}}
  <span *ngIf="!required" class="optional" i18n>*Optional</span>
  <input
    [formControlName]="name"
    (focus)="focus = true"
    (blur)="focus = false"
    [autofocus]="autofocus"
    [type]="type"
    [placeholder]="placeholder"
    [textMask]="{ mask: mask, guide: false }"
  >
  <listo-spinner *ngIf="pending" class="loading" [size]="20"></listo-spinner>
  <span *ngIf="error" [ngSwitch]="error.type" class="error-message">
    <span class="icon-error"></span>
    <ng-container *ngSwitchCase="'required'" i18n>This field is required</ng-container>
    <ng-container *ngSwitchCase="'min'" i18n>The min value is {{error.value.min}}</ng-container>
    <ng-container *ngSwitchCase="'number'" i18n>This field must be a number</ng-container>
    <ng-container *ngSwitchCase="'pattern'">
      <ng-container *ngIf="error.value.requiredPattern === '/^\\(\\d{3}\\) \\d{3}-\\d{4}$/'" i18n>
      Field must have the format (nnn) nnn-nnnn
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault i18n>Invalid field</ng-container>
  </span>
</label>
