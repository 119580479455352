import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clearCookie, getCookie } from 'src/utils/cookies';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

export interface Agent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  store: {
    id: string;
    name: string;
  };
}

const COOKIE_NAME = 'listofin_authorization';
const COOKIE_DOMAIN = environment.cookieDomain;
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated = false;
  private _currentAgent: Agent;
  private _authChanges = new Subject<boolean>();

  constructor(private http: HttpClient) {
    this.checkSession();
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  get currentAgent(): Agent {
    return this._currentAgent;
  }

  get authChanges(): Subject<boolean> {
    this.checkSession();
    return this._authChanges;
  }

  logout(): void {
    this._currentAgent = null;
    this._isAuthenticated = false;
    clearCookie(COOKIE_NAME, COOKIE_DOMAIN);
    this._authChanges.next(false);
  }

  private async checkSession(): Promise<void> {
    if (this._isAuthenticated) {
      return Promise.resolve();
    }
    this._currentAgent = await this.getAgentInfo();
    this._isAuthenticated = true;
    this._authChanges.next(true);
  }

  private async getAgentInfo(): Promise<Agent> {
    try {
      const token = getCookie(COOKIE_NAME);
      if (!token) {
        return Promise.reject();
      }

      const agentData = await this.http.get<any>(
        `https://api.listofin.com/agent-service/agents`,
        {
          withCredentials: true,
        },
      ).toPromise();

      const store = await this.getStoreInfo(agentData.store_id);
      return {
        id: agentData.agent_uid,
        firstName: agentData.first_name,
        lastName: agentData.last_name,
        email: agentData.email,
        imageUrl: agentData.image,
        store,
      };
    } catch (e) {
      this.logout();
      throw e;
    }
  }

  private async getStoreInfo(storeId: string): Promise<{ id: string, name: string }> {
    const storeData = await this.http.get<any>(
      `https://api.listofin.com/store-service/storefront/stores`,
      {
        params: { id: storeId },
        withCredentials: true,
      },
    ).toPromise();

    return {
      id: storeData.id,
      name: storeData.short_name,
    };
  }
}
