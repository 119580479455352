<span class="details">
  <ng-container  [ngSwitch]="driver.sex">
    <ng-container *ngSwitchCase="'M'" i18n>Male</ng-container>
    <ng-container *ngSwitchDefault i18n>Female</ng-container>
  </ng-container>
  /
  <ng-container  [ngSwitch]="driver.marital">
    <ng-container *ngSwitchCase="'S'" i18n>Single</ng-container>
    <ng-container *ngSwitchCase="'M'" i18n>Married</ng-container>
    <ng-container *ngSwitchCase="'D'" i18n>Divorced</ng-container>
    <ng-container *ngSwitchCase="'E'" i18n>Separated</ng-container>
    <ng-container *ngSwitchDefault i18n>Widow</ng-container>
  </ng-container>
  /
  {{driver.age}}
  <ng-container i18n> years</ng-container>
</span>
<listo-button [flat]="true" (click)="edit.emit(driver)">
  <ng-container i18n>Edit</ng-container>
</listo-button>
<listo-button *ngIf="!required" [flat]="true" (click)="delete.emit(driver)">
  <ng-container i18n>Delete</ng-container>
</listo-button>
<listo-checkbox
  i18n-label
  [value]="driver.exclude"
  (change)="onExcludeChange($event)"
  label="Exclude driver"
  [disabled]="required"
></listo-checkbox>
