import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '../../utils/types';
import { COVERAGE_TYPES } from '../../utils/constants';

@Component({
  selector: 'app-vehicle-item',
  templateUrl: './vehicle-item.component.html',
  styleUrls: ['./vehicle-item.component.scss'],
})
export class VehicleItemComponent {
  @Input() vehicle: Vehicle;
  @Input() required: boolean;
  @Output() change = new EventEmitter<Vehicle>();
  @Output() edit = new EventEmitter<Vehicle>();
  @Output() delete = new EventEmitter<Vehicle>();

  coverageTypes = COVERAGE_TYPES;

  onCoverageClick(coverage) {
    this.change.emit({
      ...this.vehicle,
      coverage,
    });
  }
}
