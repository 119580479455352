import { Component, OnInit } from '@angular/core';
import { AuthService, Agent } from '../auth.service';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'listo-agent-session-button',
  templateUrl: './agent-session-button.component.html',
  styleUrls: ['./agent-session-button.component.scss'],
})
export class AgentSessionButtonComponent implements OnInit {
  agent: Agent;
  open = false;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.agent = this.auth.currentAgent;
  }

  onClick() {
    this.open = !this.open;
    if (this.open) {
      setTimeout(() => {
        const onClick = fromEvent(document, 'click').subscribe(() => {
          onClick.unsubscribe();
          this.open = false;
        });
      });
    }
  }

  logout() {
    this.auth.logout();
  }
}
