import { Component, Input } from '@angular/core';
import { VehicleRate } from '../../utils/types';

@Component({
  selector: 'app-vehicle-rate-details',
  templateUrl: './vehicle-rate-details.component.html',
  styleUrls: ['./vehicle-rate-details.component.scss'],
})
export class VehicleRateDetailsComponent {
  @Input() vehicle: VehicleRate;

  getDpp(): number {
    const coverage = this.vehicle.coverages.find(
      ({ CoverageName }) => CoverageName === 'BI',
    );
    return (coverage && coverage.Limit[0]) || 0;
  }

  getDpa(): number {
    const coverage = this.vehicle.coverages.find(
      ({ CoverageName }) => CoverageName === 'BI',
    );
    return (coverage && coverage.Limit[1]) || 0;
  }

  getPd(): number {
    const coverage = this.vehicle.coverages.find(
      ({ CoverageName }) => CoverageName === 'PD',
    );
    return (coverage && coverage.Limit[0]) || 0;
  }

  getCd(): number | undefined {
    const coverage = this.vehicle.coverages.find(
      ({ CoverageName }) => CoverageName === 'COLL',
    );

    return coverage && coverage.Deductible;
  }

  getEcd(): number | undefined {
    const coverage = this.vehicle.coverages.find(
      ({ CoverageName }) => CoverageName === 'COMP',
    );

    return coverage && coverage.Deductible;
  }

  getUm(): boolean {
    return this.vehicle.coverages.some(
      ({ CoverageName }) => CoverageName === 'UM' || CoverageName === 'UMPD',
    );
  }
}
