import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Request } from 'src/utils/types';

export const cacheRequest = <T extends Request>(request: T): T => {
  const storage = {};

  return <T>((...args) => {
    const key = JSON.stringify(args);

    if (storage[key]) {
      return of(storage[key]);
    }

    return request(...args).pipe(
      tap(response => {
        storage[key] = response;
      }),
    );
  });
};
