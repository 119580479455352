import { SegmentService } from './segment.service';
import { Component } from '@angular/core';
import { QueryParamAnalyticsService } from './query-param-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private queryParamAnalytics: QueryParamAnalyticsService,
    private segment: SegmentService,
  ) {}
}
