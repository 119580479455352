import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Driver } from '../../utils/types';

@Component({
  selector: 'app-driver-item',
  templateUrl: './driver-item.component.html',
  styleUrls: ['./driver-item.component.scss'],
})
export class DriverItemComponent {
  @Input() driver: Driver;
  @Input() required = false;
  @Output() change = new EventEmitter<Driver>();
  @Output() edit = new EventEmitter<Driver>();
  @Output() delete = new EventEmitter<Driver>();

  onExcludeChange(exclude: boolean) {
    this.change.emit({
      ...this.driver,
      exclude,
    });
  }
}
