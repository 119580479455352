import { Component, Input } from '@angular/core';
import { STEPS } from 'src/utils/constants';
import { DataStorageService } from '../../data-storage.service';

@Component({
  selector: 'listo-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() steps: { label: string, path: string }[] = STEPS;
  @Input() currentStep: number;

  constructor(private storage: DataStorageService) {}

  canOpenStep(step) {
    const data = this.storage.getData();

    switch (step) {
      case 2:
        return data && data.quotesConfigVisited;
      case 1:
        return data && data.driversConfigVisited;
      default:
        return true;
    }
  }
}
