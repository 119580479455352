import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CONTACT_TIMES } from '../../utils/constants';
import { DataStorageService } from '../data-storage.service';
import { WebServicesService } from '../web-services.service';
import { markFormAsTouched } from '../../utils/operations';
import { ErrorService } from '../listo-common/error.service';
import { SegmentService } from '../segment.service';
import { phoneMask } from '../../utils/masks';

@Component({
  selector: 'app-personal-info-config',
  templateUrl: './personal-info-config.component.html',
  styleUrls: ['./personal-info-config.component.scss'],
})
export class PersonalInfoConfigComponent implements OnInit {
  form = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/),
    ]),
    preferred_time_to_call: new FormControl(''),
    email: new FormControl(''),
    receive_documents_authorized: new FormControl(false),
    contact_information_authorized: new FormControl(false),
  });

  contactTimes = CONTACT_TIMES;
  date: Date;
  hasCompany = false;
  loading = false;
  phoneMask = phoneMask;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private errorService: ErrorService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.form.patchValue(data);
      this.hasCompany = !!data.company_name;
    } else {
      this.router.navigate(['']);
    }
  }

  shouldShowDate() {
    return this.form.get('preferred_time_to_call').value === 'i_call';
  }

  onDateChange(date) {
    this.date = date;
  }

  trackProduct() {
    const data = this.storage.getData();
    let product;
    if (data.company_name) {
      product = {
        product_name: data.company_name,
        price: data.company_price,
      };
    }
    this.segment.track('Product Added', product);
  }

  onNextClick(e) {
    e.preventDefault();
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      return;
    }
    const formData = this.form.getRawValue();
    this.storage.patchData({
      ...formData,
      contact_information_authorized: Number(formData.contact_information_authorized),
      receive_documents_authorized: Number(formData.receive_documents_authorized),
      call_date: this.shouldShowDate() && this.date ? this.date.toISOString() : undefined,
    });

    this.loading = true;
    this.webServices.saveUserInfo(
      this.storage.getData(),
    ).subscribe(
      ({ policy }) => {
        this.trackProduct();
        this.storage.patchData({
          policyId: policy,
        });
        this.router.navigate(['goodbye']);
      },
      () => {
        this.loading = false;
        this.errorService.showError();
      },
    );
  }

  onBackClick() {
    this.router.navigate(['quotes']);
  }
}
