<span *ngIf="label && (open || error)" class="icon-focus" [class.error]="error"></span>
{{label}}
<span *ngIf="!required" class="optional" i18n>*Optional</span>
<div
  #select
  class="select"
  [class.open]="open"
  [class.error]="error"
  tabindex="0"
  (focus)="onOpen()"
  (blur)="onClose()"
  [class.placeholder]="!getSelectedLabel()"
  (mousedown)="onMouseDown($event)"
>
  {{getSelectedLabel() || placeholder}}
  <img *ngIf="!pending" class="select-icon" src="../../assets/images/dropdown-{{open ? 'open' : 'closed'}}.svg">
  <listo-spinner *ngIf="pending" class="select-icon" [size]="20"></listo-spinner>
  <div *ngIf="open" class="options" (mousedown)="$event.stopPropagation()">
    <div *ngFor="let option of options" (mousedown)="onOptionMouseDown(getOptionValue(option))">
      {{getOptionLabel(option)}}
    </div>
  </div>
</div>
<span *ngIf="error" [ngSwitch]="error" class="error-message">
  <span class="icon-error"></span>
  <ng-container *ngSwitchCase="'required'" i18n>This field is required</ng-container>
  <ng-container *ngSwitchDefault i18n>Invalid field</ng-container>
</span>
