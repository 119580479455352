import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { VehicleConfigComponent } from './vehicle-config/vehicle-config.component';
import { DriverConfigComponent } from './driver-config/driver-config.component';
import { QuotesConfigComponent } from './quotes-config/quotes-config.component';
import { PersonalInfoConfigComponent } from './personal-info-config/personal-info-config.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { LandingComponent } from './landing/landing.component';
import { ApplicationComponent } from './application/application.component';
import { LANGUAGE } from '../utils/constants';

const showLandings = <any>LANGUAGE === 'es';

const landingRoutes = showLandings
  ? [
    {
      path: '',
      component: LandingComponent,
    },
    {
      path: 'landing',
      component: LandingComponent,
      data: {
        isPhoneLanding: true,
      },
    },
  ]
  : [];

const routes: Routes = [
  ...landingRoutes,
  {
    path: '',
    component: ApplicationComponent,
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
      {
        path: 'vehicle',
        component: VehicleConfigComponent,
      },
      {
        path: 'driver',
        component: DriverConfigComponent,
      },
      {
        path: 'quotes',
        component: QuotesConfigComponent,
      },
      {
        path: 'personal',
        component: PersonalInfoConfigComponent,
      },
      {
        path: 'goodbye',
        component: GoodbyeComponent,
      },
      {
        path: '**',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
