import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'listo-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() tabs: string[];
  @Input() selectedIndex: number;
  @Output() change = new EventEmitter<number>();
}
