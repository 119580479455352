<div itemscope itemtype="https://schema.org/InsuranceAgency" class="m_body">
  <header class="m_main-header">
    <div class="g_wrapper">
      <h1 itemprop="name" class="logo">
        <span>Listo!</span>
        <img
          src="/assets/images/listo-logo.svg"
          alt="Listo! Logo"
          itemprop="logo"
        />
      </h1>
      <button class="open-cta" (click)="openOverlay($event)">Cotiza aquí en 2 minutos</button>
    </div>
  </header>
  <section class="m_hero" [class.isShowingOverlay]="isOverlayOpen">
    <div class="g_wrapper">
      <div class="value-props-container">
        <div class="m_value-props" itemprop="description">
          <button class="m_value-props--prop save" (click)="goToTable()">
            <span class="title">Ahorra dinero</span>
            <span class="subtitle">
                  Hasta $2,400 al año
                </span>
          </button>
          <a class="m_value-props--prop license" routerLink="/vehicle">
            <span class="title">Con o sin licencia</span>
          </a>
          <a class="m_value-props--prop fast" routerLink="/vehicle">
            <span class="title">Cotizaci&oacute;n en 2 minutos</span>
          </a>
        </div>
      </div>
      <div class="phone-container">
        <div class="m_phone">
          <span>tambi&eacute;n puedes llamar al</span>
          <listo-phone-link class="phone">
          </listo-phone-link>
        </div>
      </div>
      <div class="cta-container">
        <form class="m_cta m_cta--main" (submit)="onFormSubmit($event)">
          <button class="close-btn" type="button" (click)="closeOverlay($event)">Close</button>
          <header class="m_cta--main__header">
            <h3>¡Obtén tu cotización!</h3>
            <p class="m_cta--main__header-label">(Sin compromiso)</p>
          </header>
          <div class="body">
            <span>Obt&eacute;n tu cotizaci&oacute;n y compara.</span>
            <input
              name="first_name"
              type="text"
              value=""
              placeholder="Ingresa tu primer nombre"
            />
            <input
              *ngIf="isPhoneLanding"
              name="phone"
              type="text"
              value=""
              placeholder="Ingresa tu tel&eacute;fono"
            />
            <button class="btn-continue">
              Continuar
            </button>
            <button class="btn-continue-no-name">
              o si prefieres,<br />
              contin&uacute;a an&oacute;nimamente.
            </button>
          </div>
        </form>
      </div>
    </div>
    <span class="disclaimer" id="disclaimer">
          * 3 conductores, 2 veh&iacute;culos, cobertura completa
        </span>
  </section>
  <section class="m_compare-section">
    <table class="m_compare" #table>
      <thead>
        <tr>
          <th class="label">Compara por ti mismo</th>
          <th class="listo">Listo!</th>
          <th>Otros</th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td class="label"><span>Broker Fee</span></td>
          <td class="listo"><span>Pago &uacute;nico</span> <em>$75</em></td>
          <td><span>Pago inicial hasta</span> <em>$600</em></td>
        </tr>
        <tr>
          <td class="label"><span>Renovaci&oacute;n</span></td>
          <td class="listo"><span>Gratis</span> <em>$0</em></td>
          <td><span>Hasta</span> <em>$100</em></td>
        </tr>
        <tr class="odd">
          <td class="label">
            <span>Procesamiento<br />de Pagos</span>
          </td>
          <td class="listo"><span>Gratis</span> <em>$0</em></td>
          <td><span>Por pago, hasta</span> <em>$12</em></td>
        </tr>
        <tr>
          <td class="label"><span>Cambios de P&oacute;liza</span></td>
          <td class="listo"><span>Gratis</span> <em>$0</em></td>
          <td><span>Hasta</span> <em>$100</em></td>
        </tr>
        <tr class="odd">
          <td class="label"><span>Asesoramiento por Accidentes</span></td>
          <td class="listo"><span>Gratis</span> <em>$0</em></td>
          <td><span>Hasta</span> <em>¿?</em></td>
        </tr>
      </tbody>
    </table>
  </section>
  <section class="m_cta-section">
    <div class="g_wrapper top">
      <div class="title-container">
        <h2>
          Obt&eacute;n tu cotizaci&oacute;n <em>sin compromiso</em> y compara en s&oacute;lo
          <em>2 minutos</em>
        </h2>
        <div class="img">
          <img src="/assets/images/family--0,5x.jpg" alt="Father and Son in car" />
        </div>
      </div>
      <div class="cta-container">
        <form class="m_cta m_cta__bottom" (submit)="onFormSubmit($event)" [class.has-phone]="isPhoneLanding">
          <div class="body">
            <span>Obt&eacute;n tu cotizaci&oacute;n y compara.</span>
            <input
              name="first_name"
              type="text"
              value=""
              placeholder="Ingresa tu primer nombre"
            />
            <input
              *ngIf="isPhoneLanding"
              name="phone"
              type="text"
              value=""
              placeholder="Ingresa tu tel&eacute;fono"
            />
            <button class="btn-continue">
              Continuar
            </button>
            <button class="btn-continue-no-name">
              o si prefieres,<br />
              contin&uacute;a an&oacute;nimamente.
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="g_wrapper bottom">
      <div class="phone-container">
        <div class="m_phone m_phone__dark">
          <span>tambi&eacute;n puedes llamar al</span>
          <listo-phone-link class="phone">
          </listo-phone-link>
        </div>
      </div>
    </div>
  </section>
  <footer class="m_main-footer">
    <div class="g_wrapper">
      <hr />
      <div class="m_social">
        <a href="https://facebook.com/listofin" target="_blank" class="fb">Facebook</a>
        <!-- <a href="#" class="tw">Twitter</a> --> <a href="mailto:contact@listofin.com" class="email">Email</a>
      </div>
      <nav>
        <a href="#">Acerca de</a>&nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#">Pol&iacute;tica de privacidad</a>
      </nav>
      <span class="copyright">
          &copy; <a href="http://www.listofin.com/">www.listofin.com</a> 2018.
          Todos los derechos reservados.
        </span>
    </div>
  </footer>
</div>
