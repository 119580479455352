<div class="image-container">
  <img [src]="rate.logo">
</div>
<div class="header">
  <h1>{{rate.name}}</h1>
  <listo-button [flat]="true" (click)="toggleOpen()">
    <ng-container i18n>{{open ? 'Hide' : 'Show'}} coverage</ng-container>
    <span class="icon-dropdown icon-dropdown-{{open ? 'open' : 'closed'}}"></span>
  </listo-button>
</div>
<div class="payment-info">
  <p i18n>Payments from:</p>
  <h1 i18n>${{parseInt(rate.monthly_payment)}}/month</h1>
</div>
<div class="confirm-button-container">
  <listo-button (click)="success.emit(rate)">
    <ng-container i18n>I'm interested in this option</ng-container>
  </listo-button>
</div>
<ng-container *ngIf="open">
  <listo-tabs [tabs]="getTabs()" [selectedIndex]="currentVehicleIndex" (change)="currentVehicleIndex = $event"></listo-tabs>
  <app-vehicle-rate-details [vehicle]="rate.vehicles[currentVehicleIndex]"></app-vehicle-rate-details>
  <listo-button [flat]="true" (click)="toggleOpen()" style="margin: 0 auto">
    <ng-container i18n>Hide coverage</ng-container>
    <span class="icon-dropdown icon-dropdown-open"></span>
  </listo-button>
</ng-container>
