import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Coverage, Rate } from '../../utils/types';
import { VEHICLE_TAB_NAME } from '../../utils/constants';
import { SegmentService } from '../segment.service';
import { mapRateToSegmentProduct } from '../../utils/operations';

@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss'],
})
export class RateCardComponent {
  @Input() rate: Rate;
  @Output() success: EventEmitter<Rate> = new EventEmitter();

  open = false;
  currentVehicleIndex = 0;
  parseInt = parseInt;

  constructor(
    private segment: SegmentService,
  ) {}


  getTabs() {
    return this.rate.vehicles.map(
      (_, i) => `${VEHICLE_TAB_NAME} ${i + 1}`,
    );
  }

  toggleOpen() {
    this.open = !this.open;
    if (this.open) {
      this.segment.track('Product Viewed', mapRateToSegmentProduct(this.rate));
    }
  }
}
