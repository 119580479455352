import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DataStorageService } from './data-storage.service';
import { UserData } from '../utils/types';
import { WebServicesService } from './web-services.service';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QueryParamAnalyticsService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storage: DataStorageService,
    private webServices: WebServicesService,
  ) {
    this.router.events.pipe(
      first((event: RouterEvent) => event instanceof NavigationEnd),
      switchMap(() => this.activatedRoute.queryParams),
      first(),
    ).subscribe((params) => {
      this.saveUrlParams(params);
    });
  }

  saveUrlParams(paramsObject: { [key: string]: any }) {
    if (Object.keys(paramsObject).length === 0) {
      return;
    }

    const data: UserData = {
      first_name: paramsObject.name,
      last_name: paramsObject.last_name,
      phone: paramsObject.phone,
      email: paramsObject.email,
      preferred_time_to_call: paramsObject.preferred_time_to_call,
      company_name: paramsObject.company_name,
      customer_id: paramsObject.customer_id,
      transaction_id: paramsObject.transaction_id,
      call_date: paramsObject.call_date,
      referrer: paramsObject.referrer,
      sid: paramsObject.sid,
      cid: paramsObject.cid,
      url_params: paramsObject,
    };

    this.storage.patchData(data);

    if (data.phone) {
      this.webServices.sendZapierData(data).subscribe();
    }
  }
}
