<div class="label">
  <span *ngIf="error" class="icon-focus error"></span>
  {{label}}
</div>
<div class="options">
  <div *ngFor="let option of options" class="option" (click)="onOptionClick(getOptionValue(option))"
       [class.selected]="isOptionSelected(option)">
    {{getOptionLabel(option)}}
  </div>
</div>
<span *ngIf="error" [ngSwitch]="error" class="error-message">
  <span class="icon-error"></span>
  <ng-container *ngSwitchCase="'required'" i18n>This field is required</ng-container>
  <ng-container *ngSwitchDefault i18n>Invalid field</ng-container>
</span>
