import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LANGUAGE } from '../../../utils/constants';

@Component({
  selector: 'listo-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent {
  @Input() open: boolean;
  @Output() close = new EventEmitter();
  lang = LANGUAGE;
}
