import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

Sentry.init({
  dsn: 'https://020dac58105343068fb9382f54388c08@sentry.io/1397766',
});

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService implements ErrorHandler {
  handleError(error) {
    console.log(error);
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
