import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebServicesService } from '../web-services.service';
import { DataStorageService } from '../data-storage.service';
import { SegmentService } from '../segment.service';
import { UserData } from '../../utils/types';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isPhoneLanding = false;
  isOverlayOpen = false;
  @ViewChild('table') tableElement;

  constructor(
    private route: ActivatedRoute,
    private webServices: WebServicesService,
    private storage: DataStorageService,
    private segment: SegmentService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ isPhoneLanding }) => {
      this.isPhoneLanding = isPhoneLanding;
    });

    const data = this.storage.getData() || {};
    const { sid } = data;
    this.storage.clearData();

    if (sid) {
      this.storage.setData({ sid });
    }
  }

  closeOverlay(e) {
    e.preventDefault();
    this.isOverlayOpen = false;
  }

  openOverlay(e) {
    e.preventDefault();
    this.isOverlayOpen = true;
  }

  onFormSubmit(e) {
    e.preventDefault();
    const form = e.target;
    this.doSubmit(form);
  }

  doSubmit(form) {
    const formData = new FormData(form);
    const paramsData = new URLSearchParams(window.location.search);
    const params: any = {};
    // @ts-ignore
    paramsData.forEach((value, key) => {
      params[key] = value;
    });

    const data: UserData = {
      first_name: String(formData.get('first_name')),
      phone: String(formData.get('phone')),
      url_params: params,
    };

    this.storage.patchData(data);
    if (this.isPhoneLanding && data.phone) {
      this.webServices.sendZapierData(data).subscribe();
    }
    this.segment.track('Product Searched', data);
    this.router.navigate(['vehicle']);
  }

  goToTable() {
    this.tableElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
