import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  providers: [TitleCasePipe],
})
export class WelcomeComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(
    private router: Router,
    private storage: DataStorageService,
    private titleCasePipe: TitleCasePipe,
  ) {}

  ngOnInit() {
    const data = this.storage.getData() || {};
    const { referrer, sid } = data;
    this.storage.clearData();

    if (sid) {
      this.storage.setData({ sid });
    }
    if (referrer) {
      const url = new URL(referrer);
      if (sid) {
        url.searchParams.set('sid', sid);
      }
      document.location.href = url.href;
    }
  }

  onNextClick() {
    const name = this.form.get('name').value;
    if (name) {
      this.storage.patchData({
        first_name: this.titleCasePipe.transform(name),
      });
    } else {
      this.storage.patchData({});
    }
    this.router.navigate(['vehicle']);
  }
}
