import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CIVIL_STATUSES, GENDERS, LICENSE_TYPES, OTHER_CIVIL_STATUSES } from '../../utils/constants';
import { Option } from '../../utils/types';
import CustomValidators from 'src/utils/validators';

export class DriverForm extends FormGroup {
  constructor() {
    super({
      sex: new FormControl(null, CustomValidators.notNull),
      marital: new FormControl(null, Validators.required),
      age: new FormControl('', [Validators.required, CustomValidators.number, Validators.min(18)]),
      zip_code: new FormControl(''),
      license_type: new FormControl('', Validators.required),
    });
  }
}

@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss'],
})
export class DriverFormComponent {
  @Input() form: DriverForm;
  @Input() showZipCode: boolean;

  genders: Option[] = GENDERS;
  civilStatuses: Option[] = CIVIL_STATUSES;
  otherCivilStatuses: Option[] = OTHER_CIVIL_STATUSES;
  licenseTypes: Option[] = LICENSE_TYPES;

  shouldShowOtherCivilStatuses() {
    const value = this.form.get('marital').value;
    return value !== 'M' && value !== 'S' && value !== null;
  }
}
