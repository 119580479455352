import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

interface OptionObject {
  label: string;
  value: any;
}

type Option = OptionObject | string;

@Component({
  selector: 'listo-radios',
  templateUrl: './radios.component.html',
  styleUrls: ['./radios.component.scss'],
})
export class RadiosComponent {
  @Input() options: Option[];
  @Input() form = new FormControl();
  @Input() name: string;
  @Input() label!: string;

  getOptionLabel(option: Option): string {
    if (typeof option === 'string') {
      return option;
    }
    return option.label;
  }

  getOptionValue(option: Option): any {
    if (typeof option === 'string') {
      return option;
    }
    return option.value;
  }

  getCurrentValue() {
    return this.form.get(this.name).value;
  }

  isOptionSelected(option) {
    return this.getOptionValue(option) === this.getCurrentValue();
  }

  onOptionClick(value) {
    this.form.get(this.name).markAsTouched();
    this.form.patchValue({
      [this.name]: value,
    });
  }

  get error() {
    if (!this.form || !this.name) {
      return false;
    }
    const control = this.form.get(this.name);
    return control && control.touched && control.errors && Object.keys(control.errors)[0];
  }
}
