import { Driver, Rate, Vehicle, VehicleRate } from './types';

export const filterVehicles = ({ coverage }: Vehicle) => coverage;

export const filterDrivers = ({ exclude }: Driver) => !exclude;

export const mapDrivers = (driver: Driver, i): Driver => ({
  ...driver,
  is_insured: i === 0 ? 1 : 0,
});

export const mapVehicles = (vehicle: Vehicle): Vehicle => ({
  ...vehicle,
  exclude: !vehicle.coverage,
});

export const ratesToArray = (rates): Rate[] => Object.keys(rates).map(name => ({
  ...rates[name],
  name,
}));

export const formatRate = (vehicles: Vehicle[]) => (rate: Rate): Rate => {
  let vehicleRateIndex = 0;
  const vehicleRates = vehicles.map((vehicle: Vehicle): VehicleRate => {
    let coverages = [];
    if (vehicle.coverage) {
      coverages = rate.vehicles[vehicleRateIndex++].Coverages;
    }
    return {
      ...vehicle,
      coverages,
    };
  });

  return {
    ...rate,
    vehicles: vehicleRates,
  };
};

export const sortRates = (a: Rate, b: Rate) => Number(a.monthly_payment) - Number(b.monthly_payment);

export const markFormAsTouched = (form: { controls?: any, markAsTouched(): void }) => {
  form.markAsTouched();
  if (form.controls) {
    Object.values(form.controls).forEach(markFormAsTouched);
  }
};

export const getObjectFromParam = (params: URLSearchParams, paramName: string) => {
  return params.has(paramName) && {
    [paramName]: params.get(paramName),
  };
};

export const mapRateToSegmentProduct = (rate: Rate, i?: number) => {
  const isInArray = i !== undefined;
  return {
    price: rate.monthly_payment,
    ...(isInArray
        ? {
          name: rate.name,
          position: i + 1,
        }
        : {
          product_name: rate.name,
        }
    ),
  };
};

export const getSegmentPage = () => {
  const page = window.location.pathname;
  switch (page) {
    case '/':
      return 'AutoInsuranceRater LandingPage';
    case '/welcome':
      return 'AutoInsuranceRater WelcomePage';
    case '/vehicle':
      return 'AutoInsuranceRater VehiclePage';
    case '/driver':
      return 'AutoInsuranceRater DriverPage';
    case '/quotes':
      return 'AutoInsuranceRater QuotesPage';
    case '/personal':
      return 'AutoInsuranceRater PersonalPage';
    case '/goodbye':
      return 'AutoInsuranceRater GoodbyePage';
    default:
      return page;
  }
};
