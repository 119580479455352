import { NgModule } from '@angular/core';
import { TopbarComponent } from './topbar/topbar.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { StepperComponent } from './stepper/stepper.component';
import { SelectComponent } from './select/select.component';
import { TabsComponent } from './tabs/tabs.component';
import { RadiosComponent } from './radios/radios.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { DateSelectComponent } from './date-select/date-select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TrackOnClickDirective } from '../track-on-click.directive';
import { PhoneLinkComponent } from './phone-link/phone-link.component';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { AgentSessionButtonComponent } from './agent-session-button/agent-session-button.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    ReactiveFormsModule,
    TextMaskModule,
    BrowserModule,
    RouterModule,
  ],
  declarations: [
    TopbarComponent,
    InputComponent,
    ButtonComponent,
    StepperComponent,
    SelectComponent,
    TabsComponent,
    RadiosComponent,
    CheckboxComponent,
    ModalComponent,
    BottomBarComponent,
    DateSelectComponent,
    SpinnerComponent,
    ErrorModalComponent,
    TrackOnClickDirective,
    PhoneLinkComponent,
    PrivacyModalComponent,
    AgentSessionButtonComponent,
  ],
  exports: [
    TopbarComponent,
    InputComponent,
    ButtonComponent,
    StepperComponent,
    SelectComponent,
    TabsComponent,
    RadiosComponent,
    CheckboxComponent,
    ModalComponent,
    BottomBarComponent,
    DateSelectComponent,
    SpinnerComponent,
    ErrorModalComponent,
    PhoneLinkComponent,
    PrivacyModalComponent,
  ],
})
export class ListoCommonModule {}
