<div class="label-container">
  <label>{{vehicle.year + ' ' + vehicle.maker + ' ' + vehicle.model | titlecase}}</label>
  <listo-button [flat]="true" (click)="edit.emit(vehicle)">
    <ng-container i18n>Edit</ng-container>
  </listo-button>
  <listo-button *ngIf="!required" [flat]="true" (click)="delete.emit(vehicle)">
    <ng-container i18n>Delete</ng-container>
  </listo-button>
</div>
<div class="coverages-container">
  <div
    *ngFor="let coverageType of coverageTypes; let i = index"
    (click)="onCoverageClick(coverageType.value)"
    class="coverage"
    [class.selected]="coverageType.value === vehicle.coverage"
    [class.first]="i === 0"
    [class.disabled]="required && i===0"
  >
    {{coverageType.label}}
  </div>
</div>
