<listo-modal [open]="errorService.open" (close)="errorService.hideError()">
  <img src="../../../assets/images/error.svg">
  <h1 i18n>Oh, no!</h1>
  <h1 i18n>We're having problems with the Internet connection</h1>
  <p i18n>Call us at</p>
  <h2><listo-phone-link></listo-phone-link></h2>
  <p i18n>Our advisers can help you</p>
  <p i18n>Or try</p>
  <listo-button (click)="reload()">
    <ng-container i18n>Reload page</ng-container>
  </listo-button>
  <listo-button (click)="reset()" [invertColor]="true">
    <ng-container i18n>Go back to the main page</ng-container>
  </listo-button>
</listo-modal>
