import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, repeat, switchMap, takeWhile } from 'rxjs/operators';
import { Driver, Store, UserData, Vehicle } from '../utils/types';
import { formatRate, mapDrivers, mapVehicles, ratesToArray, sortRates } from 'src/utils/operations';
import { DataStorageService } from './data-storage.service';
import { SegmentService } from './segment.service';
import { cacheRequest } from '../utils/cache';
import { SentryErrorHandlerService } from './listo-common/sentry-error-handler.service';
import { AuthService } from './listo-common/auth.service';
import { throwError, timer } from 'rxjs';

const API_URL = environment.apiUrl;
const ZC_API_URL = environment.zipCodeApiUrl;

@Injectable({
  providedIn: 'root',
})
export class WebServicesService {
  constructor(
    private http: HttpClient,
    private storage: DataStorageService,
    private sentry: SentryErrorHandlerService,
    private auth: AuthService,
  ) {}

  get options() {
    const { sid, cid }: any = this.storage.getData() || {};
    const segmentUserId = SegmentService.getId();
    const headers = {
      'Content-Type': 'text/plain',
    };
    if (sid) {
      headers['Listo-Affiliate-Source-Id'] = sid;
    }
    if (cid) {
      headers['Listo-Affiliate-Click-Id'] = cid;
    }
    if (segmentUserId) {
      headers['Listo-Analytics-Segment-User-Id'] = segmentUserId;
    }
    return {
      headers,
      withCredentials: true,
    };
  }

  getVehicleYears = cacheRequest(() => {
    return this.http.get<{ years: string[] }>(
      `${API_URL}/vehicle_information/years`,
      this.options,
    ).pipe(
      catchError(this.handleError),
    );
  });

  getVehicleMakers = cacheRequest((year: string) => {
    return this.http.post<{ makers: string[] }>(
      `${API_URL}/vehicle_information/makers`,
      { year },
      this.options,
    ).pipe(
      catchError(this.handleError),
    );
  });

  getVehicleModels = cacheRequest((year: string, maker: string) => {
    return this.http.post<{ models: string[] }>(
      `${API_URL}/vehicle_information/models`,
      { year, maker },
      this.options,
    ).pipe(
      catchError(this.handleError),
    );
  });

  getNearestStore = cacheRequest((zipcode: string) => {
    return this.http.get<{ store: Store }>(
      `${ZC_API_URL}/storefront/${zipcode}`,
    ).pipe(
      map(({ store }): Store => store),
      catchError(this.handleError),
    );
  });

  private asyncFetch<T>(baseUrl: string, limit: number = 5, interval: number = 2000) {
    interface Response {
      status: 'complete' | 'processing' | 'pending' | 'cancelled' | 'error';
      progress?: number;
      message?: string;
      data?: T;
    }

    return switchMap((res: { resultsUrl: string }, index) =>
      timer(index && interval).pipe(
        switchMap(() =>
          this.http.get<Response>(baseUrl + res.resultsUrl, {
            params: {
              noCache: String(Date.now()),
            },
            ...this.options,
          }),
        ),
        repeat(limit),
        takeWhile(({ status }) => {
          return status === 'processing' || status === 'pending';
        }, true),
      ),
    );
  }

  getRates(vehicles: Vehicle[], drivers: Driver[]) {
    return this.http.post(
      `${API_URL}/rate_engine/rates`,
      { vehicles, drivers },
      this.options,
    ).pipe(
      this.asyncFetch<{
        rates: {};
        customer_id: string;
        transaction_id: string;
      }>(API_URL),
      map(({ progress, data }) => ({
        ...data,
        rates: ratesToArray(data.rates).map(formatRate(vehicles)).sort(sortRates),
        progress,
      })),
      catchError(this.handleError),
    );
  }

  saveUserInfo(data: UserData) {
    return this.http.post<{ policy: string }>(
      `${API_URL}/contact/save_user_info`,
      {
        ...data,
        vehicles: data.vehicles.map(mapVehicles),
        drivers: data.drivers.map(mapDrivers),
      },
      this.options,
    ).pipe(
      catchError(this.handleError),
    );
  }

  sendZapierData(data: UserData) {
    let agentEmail;
    if (this.auth.isAuthenticated) {
      agentEmail = this.auth.currentAgent.email;
    }
    return this.http.post(
      'https://hooks.zapier.com/hooks/catch/1949618/garlik/',
      {
        language: 'spanish',
        firstName: data.first_name,
        lastName: data.last_name,
        phone: data.phone,
        ...data.url_params,
        agentEmail,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  handleError = (e) => {
    this.sentry.handleError(e);
    return throwError(e);
  };
}
