<img class="image" src="../../assets/images/thank-you.svg" i18n-src>
<h2>
  <ng-container *ngIf="!willCall" i18n>{{name}}, an advisor will contact you shortly</ng-container>
  <ng-container *ngIf="willCall" i18n>{{name}}, we await your call at <listo-phone-link></listo-phone-link></ng-container>
</h2>
<p i18n>You can also visit us in our nearest store</p>
<p><b>{{address}}</b></p>
<iframe *ngIf="url" [src]="url"></iframe>
<ng-container *ngIf="!willCall">
  <p i18n>Or call us today</p>
  <p><b><listo-phone-link></listo-phone-link></b></p>
</ng-container>

<listo-button (click)="onNextClick()">
  <ng-container i18n>New Quote</ng-container>
</listo-button>
<a *ngIf="isLogged()" [href]="getPolicyUrl()" target="_blank">
  <listo-button [invertColor]="true">
    <ng-container i18n>Continue in portal</ng-container>
  </listo-button>
</a>
