export function setCookie(key: string, value: string, domain: string, expiresIn: number): void {
  document.cookie = `${key}=${value}; domain=${domain}; max-age=${expiresIn}`;
}

export function clearCookie(key: string, domain: string): void {
  document.cookie = `${key}=; domain=${domain}; max-age=0`;
}

export function getCookie(key: string): string | void {
  if (!document.cookie) {
    return;
  }

  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const [_key, value] = decodeURIComponent(cookie).trim().split('=');
    if (_key === key) {
      return value;
    }
  }
}
