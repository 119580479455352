import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { Location } from '@angular/common';
import { WebServicesService } from '../web-services.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAPS_API_KEY } from '../../utils/constants';
import { AuthService } from '../listo-common/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss'],
})
export class GoodbyeComponent implements OnInit {
  name: string;
  address: string;
  url: SafeResourceUrl;
  willCall = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.name = data.first_name;
      this.willCall = data.preferred_time_to_call === 'i_call';
      this.getStore();
    } else {
      this.router.navigate(['']);
    }
  }

  getStore() {
    const { drivers } = this.storage.getData();
    const zipCode = drivers[0].zip_code;
    this.webServices.getNearestStore(zipCode).subscribe(
      ({
        address,
        latitude,
        longitude,
      }) => {
        this.address = address;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}&q=${latitude},${longitude}`,
        );
      },
    );
  }

  onNextClick() {
    this.router.navigate(['']);
  }

  isLogged() {
    return !!this.auth.currentAgent;
  }

  getPolicyUrl() {
    const { policyId } = this.storage.getData();
    return `${environment.expandedRaterUrl}/autorater/quote/${policyId}`;
  }
}
