import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { FormArray, Validators } from '@angular/forms';
import { DRIVER_TAB_NAME } from 'src/utils/constants';
import { DriverForm } from '../driver-form/driver-form.component';
import { Driver, UserData } from 'src/utils/types';
import { getSegmentPage, markFormAsTouched } from 'src/utils/operations';
import CustomValidators from 'src/utils/validators';
import { isScreenSmall } from 'src/utils/screen';
import { WebServicesService } from '../web-services.service';
import { SegmentService } from '../segment.service';

@Component({
  selector: 'app-driver-config',
  templateUrl: './driver-config.component.html',
  styleUrls: ['./driver-config.component.scss'],
})
export class DriverConfigComponent implements OnInit {
  name: string;
  form = new FormArray([
    new DriverForm(),
  ]);

  get currentItem() {
    return Number(this.activatedRoute.snapshot.queryParamMap.get('currentItem')) || 0;
  }

  set currentItem(currentItem) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { currentItem },
      replaceUrl: true,
    });
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    } else {
      this.router.navigate(['']);
    }
  }

  setComponentData({ first_name = '', drivers = [] }: UserData) {
    this.setZipCodeObserver();
    this.name = first_name;
    drivers.forEach((driver, i) => {
      const driverForm = new DriverForm();
      driverForm.patchValue(driver);
      this.form.setControl(i, driverForm);

    });
    if (this.activatedRoute.snapshot.queryParamMap.get('new')) {
      this.addDriver();
    } else if (this.currentItem >= this.form.length) {
      this.currentItem = this.form.length - 1;
    }
    this.form.updateValueAndValidity();
    this.storage.patchData({ driversConfigVisited: true });
  }

  setZipCodeObserver() {
    this.form.valueChanges.subscribe(() => {
      const firstZipCode = this.form.controls[0].get('zip_code');

      if (!firstZipCode.validator || !firstZipCode.asyncValidator) {
        firstZipCode.setAsyncValidators(CustomValidators.webService(
          value => this.webServices.getNearestStore(value),
        ));
        firstZipCode.setValidators([Validators.required, CustomValidators.number]);
        firstZipCode.updateValueAndValidity();
      }

      this.form.controls.slice(1).forEach((form) => {
        if (firstZipCode.value === form.get('zip_code').value) {
          return;
        }
        form.patchValue({
          zip_code: firstZipCode.value,
        });
      });
    });
  }

  getTabs() {
    let tabName = DRIVER_TAB_NAME;
    if (isScreenSmall()) {
      tabName = tabName.slice(0, 1);
    }
    return this.form.controls.map(
      (_, i) => tabName + (i + 1),
    );
  }

  onTabChange(index: number) {
    this.currentItem = index;
  }

  addDriver() {
    this.form.push(new DriverForm());
    this.currentItem = this.form.length - 1;
    this.form.markAsUntouched();
    this.trackDrivers();
  }

  deleteDriver() {
    this.form.removeAt(this.currentItem);
    if (this.currentItem === this.form.length) {
      this.currentItem--;
    }
  }

  onNextClick() {
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      this.currentItem = this.form.controls.findIndex(
        control => control.invalid,
      );
      return;
    }
    const drivers: Driver[] = this.form.getRawValue();
    this.storage.patchData({
      drivers: drivers.map((driver): Driver => ({
        ...driver,
        age: Number(driver.age),
        exclude: false,
      })),
    });
    this.trackDrivers();
    this.router.navigate(['quotes']);
  }

  onBackClick() {
    this.router.navigate(['vehicle']);
  }

  trackDrivers() {
    this.segment.track(`${getSegmentPage()} DriverEntered`, {
      drivers: this.form.getRawValue(),
    });
  }
}
