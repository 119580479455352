import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { FormArray } from '@angular/forms';
import { VehicleForm } from '../vehicle-form/vehicle-form.component';
import { VEHICLE_TAB_NAME } from '../../utils/constants';
import { UserData } from '../../utils/types';
import { markFormAsTouched, getSegmentPage } from 'src/utils/operations';
import { isScreenSmall } from 'src/utils/screen';
import { SegmentService } from '../segment.service';

@Component({
  selector: 'app-vehicle-config',
  templateUrl: './vehicle-config.component.html',
  styleUrls: ['./vehicle-config.component.scss'],
})
export class VehicleConfigComponent implements OnInit {
  name: string;
  form = new FormArray([
    new VehicleForm(),
  ]);

  get currentItem() {
    return Number(this.activatedRoute.snapshot.queryParamMap.get('currentItem')) || 0;
  }

  set currentItem(currentItem) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { currentItem },
      replaceUrl: true,
    });
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: DataStorageService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    }
  }

  setComponentData({ first_name = '', vehicles = [] }: UserData) {
    this.name = first_name;
    vehicles.forEach((vehicle, i) => {
      const vehicleForm = new VehicleForm();
      vehicleForm.patchValue(vehicle);
      this.form.setControl(i, vehicleForm);
    });

    if (this.activatedRoute.snapshot.queryParamMap.get('new')) {
      this.addVehicle();
    } else if (this.currentItem >= this.form.length) {
      this.currentItem = this.form.length - 1;
    }
  }

  getTabs() {
    let tabName = VEHICLE_TAB_NAME;
    if (isScreenSmall()) {
      tabName = tabName.slice(0, 1);
    }
    return this.form.controls.map(
      (_, i) => tabName + (i + 1),
    );
  }

  onTabChange(index: number) {
    this.currentItem = index;
  }

  addVehicle() {
    this.form.push(new VehicleForm());
    this.currentItem = this.form.length - 1;
    this.trackVehicles();
  }

  deleteVehicle() {
    this.form.removeAt(this.currentItem);
    if (this.currentItem === this.form.length) {
      this.currentItem--;
    }
  }

  onNextClick() {
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      this.currentItem = this.form.controls.findIndex(
        control => control.invalid,
      );
      return;
    }
    const vehicles = this.form.getRawValue();
    this.storage.patchData({
      vehicles: vehicles.map(vehicle => ({
        ...vehicle,
        coverage: 'basic',
      })),
    });
    this.trackVehicles();
    this.router.navigate(['driver']);
  }

  onBackClick() {
    this.router.navigate(['']);
  }

  trackVehicles() {
    this.segment.track(`${getSegmentPage()} VehicleSelected`, {
      vehicles: this.form.getRawValue(),
    });
  }
}
