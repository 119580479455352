import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

interface OptionObject {
  label: string;
  value: any;
}

type Option = OptionObject | string;

@Component({
  selector: 'listo-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() options: Option[] = [];
  @Input() form: FormControl;
  @Input() name: string;
  @Input() label!: string;
  @Input() required = false;
  @Input() placeholder?: string;
  @Input() loading?: boolean;
  @Input() value: any;
  @Output() change = new EventEmitter();
  @ViewChild('select') selectElement;

  open = false;

  getOptionLabel(option: Option): string {
    if (typeof option === 'object') {
      return option.label;
    }
    return option;
  }

  getOptionValue(option: Option): any {
    if (typeof option === 'object') {
      return option.value;
    }
    return option;
  }

  getValue() {
    if (this.form) {
      const control = this.form.get(this.name);
      return control && control.value;
    }
    return this.value;
  }

  getSelectedLabel(): string {
    const value = this.getValue();
    const selected = this.options.find(
      option => this.getOptionValue(option) === value,
    );
    return selected ? this.getOptionLabel(selected) : '';
  }

  onOpen() {
    this.open = true;
  }

  onClose() {
    if (this.form) {
      this.form.get(this.name).markAsTouched();
    }
    this.open = false;
  }

  onOptionMouseDown(value) {
    this.onClose();
    this.selectElement.nativeElement.blur();
    if (this.form) {
      this.form.patchValue({
        [this.name]: value,
      });
    }
    this.change.emit(value);
  }

  onMouseDown(event) {
    if (this.open) {
      event.preventDefault();
      this.selectElement.nativeElement.blur();
    }
  }

  get pending() {
    if (!this.form) {
      return this.loading;
    }
    const control = this.form.get(this.name);
    return control && control.pending || this.loading;
  }

  get error() {
    if (!this.form) {
      return;
    }
    const control = this.form.get(this.name);
    return control && control.touched && control.errors && Object.keys(control.errors)[0];
  }
}
