<div class="header">
  <span>
    <b i18n>Car: </b>
    {{vehicle.year + ' ' + vehicle.maker + ' ' + vehicle.model | titlecase}}
  </span>
  <span>
    <b i18n>Type of coverage: </b>
    <ng-container [ngSwitch]="vehicle.coverage">
      <ng-container *ngSwitchCase="'max'" i18n>Full</ng-container>
      <ng-container *ngSwitchCase="'medium'" i18n>Medium</ng-container>
      <ng-container *ngSwitchCase="'basic'" i18n>Basic</ng-container>
      <ng-container *ngSwitchDefault i18n>No coverage</ng-container>
    </ng-container>
  </span>
</div>
<div class="body">
  <table *ngIf="vehicle.coverage">
    <thead>
    <tr>
      <th colspan="2" i18n>Damage for third parties</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td i18n>Physical per person</td>
      <td>${{getDpp() | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    <tr>
      <td i18n>Physical per accident</td>
      <td>${{getDpa() | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    <tr>
      <td i18n>Coverage to the property</td>
      <td>${{getPd() | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    </tbody>
  </table>
  <table *ngIf="vehicle.coverage">
    <thead>
    <tr>
      <th colspan="2" i18n>Damage to your car, to you and your passengers</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="getCd()">
      <td i18n>Collision (deductible)</td>
      <td>${{getCd() | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    <tr *ngIf="getEcd()">
      <td i18n>Comprehensive (deductible)</td>
      <td>${{getEcd() | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    <tr>
      <td i18n>Uninsured motorist</td>
      <td i18n>{{getUm() ? 'Included' : 'Not included'}}</td>
    </tr>
    </tbody>
  </table>
</div>
