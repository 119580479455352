import { Component } from '@angular/core';
import { ErrorService } from '../error.service';
import { Router } from '@angular/router';

@Component({
  selector: 'listo-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {

  constructor(
    public errorService: ErrorService,
    private router: Router,
  ) {}

  reload() {
    window.location.reload();
  }

  reset() {
    this.errorService.hideError();
    this.router.navigate(['']);
  }
}
